.bs-avatar {
  $avatar: &;
  position: relative;
  z-index: 0;
    
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(-20deg, hslAlpha($color_deepBlack, 0.5) 0%, hslAlpha($color_deepBlack, 0) 50%);
    border-radius: 50%;
    -webkit-mask: 
       linear-gradient(black 0 0) content-box, 
       linear-gradient(black 0 0);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    background: $gradient_light;

    &--placeholder {
      object-fit: contain;

      path {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--small {
    padding: rem(2);
    width: rem(40);
    height: rem(40);

    &::before {
      padding: rem(2);
    }

    #{$avatar}__image--placeholder {
      padding: rem(5);
    }
  }

  &--medium {
    padding: rem(3);
    width: rem(120);
    height: rem(120);

    &::before {
      padding: rem(3);
    }

    #{$avatar}__image--placeholder {
      padding: rem(15);
    }
  }

  &--big {
    padding: rem(5);
    width: rem(220);
    height: rem(220);

    &::before {
      padding: rem(5);
    }

    #{$avatar}__image--placeholder {
      padding: rem(25);
    }
  }
}

.bs-theme--dark .bs-avatar {

  &__image {
    background: $color_deepBlack;
  }
}
