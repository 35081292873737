.bs-cv-project-form {
  &__table-wrapper {
    flex: 1 1 100%;
    margin-bottom: rem(16);

    table {
      width: 100%;

      td {
        padding: rem(4);
        word-break: break-all;

        &:nth-child(1) {
          text-align: center;
          width: rem(50);
        }
        &:nth-child(3) {
          text-align: left;
          width: rem(300);
        }
      }
      tr {
        cursor: pointer;
      }
      thead {
        td {
          background: $color_light_orange;
          font-weight: 500;
        }
      }
      tbody {
        tr:nth-child(even) {
          background: $color_lighterGrey;
        }
        tr:nth-child(odd) {}

        tr.bs-cv-project-form__table__selected-project {
          color: $color_darkOrange;
          font-weight: bold;
        }
      }
    }
  }
  &__blackbelt-project-description{
    font-style: italic;
    margin: rem(16) 0;
    padding-left: rem(16);
    border-left: solid 4px $color_cloudGrey;
  }
  &__mode-selector {
    flex-direction: column;
    width: 100%;

    > div {
      display: inline-flex;
    }
  }
}

.bs-theme--dark {
  .bs-cv-project-form {
    thead {
      td {
        color: $color_lighterGrey;
        background: $color_darkOrange;
        font-weight: 500;
      }
    }
    table {
      tbody {
        tr:nth-child(even) {
          background: $color_powderGrey;
        }
      }
    }
  }
}
