.bs-loading__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  animation-name: bs-loading-background-animation;
  animation-delay: 1.2s;
  animation-duration: 0.6s;
  animation-direction: normal;
  animation-fill-mode: forwards;

  &__progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    > * {
      width: 100%;
    }
  }

  p {
    text-align: center;
    color: $color_darkOrange;
    background: $color_gunGrey;
    margin: rem(-84);
    padding: rem(32);

    animation-name: bs-loading-text-animation;
    animation-delay: 5s;
    animation-duration: 0.6s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
}

@keyframes bs-loading-background-animation {
  0% { background: rgba(0,0,0,0) }
  100% { background: rgba(0, 0, 0, 0.3) }
}
@keyframes bs-loading-text-animation {
  0% { margin: rem(-84) }
  100% { margin: rem(0) }
}

.bs-theme--dark .bs-loading__wrapper {
  p {
    font-weight: 700;
    color :$color_orange;
    background: $color_black;
  }
}
