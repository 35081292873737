.bs-notification-card {
  $notificationCard: &;
  position: relative;
  margin: rem(20) 0;

  &__box {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(20);
    padding: rem(22) rem(24);
    background: $color_white;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__text {
    padding: rem(8) 0;
    line-height: rem(20);
    font-size: rem(16);
    color: $color_deepBlack;
    text-align: right;
  }

  &__icon {
    position: relative;
    width: rem(36);
    height: rem(36);

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__message {
    font-style: italic;
    text-align: right;
    word-break: break-all;
  }

  &__uploader {
    margin: rem(-18) 0 0 rem(24);
  }
}

.bs-theme--dark .bs-notification-card {

  &__box {
    background: $gradient_darkAlpha;
  }

  &__text {
    color: $color_white;
  }

  &__uploader .bs-datetime-tag {
    background: $color_gunGrey;
    color: $color_white;
  }
}
