@use "sass:color";

@import "./function";

// FONTS
$font: (
  'sourceSans': 'Source Sans 3',
);

// TYPO
$typo: (
  'font': map-get($font, 'sourceSans'),
  'size': 16,
  'weight': 400,
);

// BREAKPOINTS
$breakpoint: (
  'desktop_4': 3840px,
  'desktop_3': 2560px,
  'desktop_2': 1920px,
  'desktop_1': 1650px,
  'laptop_2': 1366px,
  'laptop_1': 1024px,
  'tablet_2': 960px,
  'tablet_1': 768px,
  'mobile_3': 640px,
  'mobile_2': 480px,
  'mobile_1': 320px,
);

// Z-INDEX
$z-index: (
  'header': 10,
);

// COLORS
// Colors must be in HSL(A)
// Only use colors defined by css variable

// Base colors:
$color_white: hsl(0, 0%, 100%);
$color_lightGrey: hsl(36, 38%, 97%);
$color_lighterGrey: hsl(6, 12%, 98%);
$color_cloudGreyAlphaLight: hsla(270, 2%, 80%, 0.125);
$color_cloudGreyAlpha: hsla(270, 2%, 80%, 0.25);
$color_cloudGrey: hsl(270, 2%, 80%);
$color_powderGrey: hsl(270, 4%, 40%);
$color_steelGrey: hsl(268, 10%, 30%);
$color_gunGrey: hsl(264, 10%, 20%);
$color_deepBlack: hsl(267, 17%, 10%);
$color_derpBlack: hsl(267, 17%, 15%);
$color_black: hsl(0, 0%, 0%);
$color_orange: hsl(19, 89%, 64%);
$color_midOrange: hsl(19, 88%, 70%);
$color_light_orange: hsl(19, 78%, 84%);
$color_darkOrange: hsl(19, 91%, 46%);
$color_lightPurple: hsl(238, 32%, 75%);
$color_fadedPurple: hsl(266, 16%, 50%);
$color_pastelPurple: hsl(266, 16%, 30%);
$color_pastelPurple_alpha: hsla(266, 16%, 30%, 0.4);
$color_purple: hsl(233, 27%, 38%);
$color_darkPurple: hsl(234, 29%, 26%);
$color_green: hsl(148, 79%, 54%);
$color_darkGreen: hsl(153, 75%, 42%);
$color_red: hsl(0, 79%, 54%);
$color_darkRed: hsl(0, 75%, 42%);

$gradient_orange: linear-gradient(98deg, $color_orange 0%, $color_darkOrange 100%);
$gradient_orangeLight: linear-gradient(98deg, $color_orange 0%, $color_midOrange  100%);
$gradient_purple: linear-gradient(97.38deg, $color_purple 0%, $color_darkPurple 100%);
$gradient_green: linear-gradient(97.38deg, $color_green 0%, $color_darkGreen 100%);
$gradient_red: linear-gradient(97.38deg, $color_red 0%, $color_darkRed 100%);
$gradient_fadedPurple: linear-gradient(87.5deg, $color_fadedPurple 0%, $color_pastelPurple 100%);
$gradient_fadedPurpleAlpha: linear-gradient(100.28deg, hslAlpha($color_fadedPurple, .25) 0%, hslAlpha($color_pastelPurple, .25) 100%);
$gradient_dark: linear-gradient(100.28deg, $color_steelGrey 0%, $color_gunGrey 100%);
$gradient_darkGrey: linear-gradient(0deg, $color_deepBlack 0%, $color_derpBlack 100%);
$gradient_darkAlpha: linear-gradient(100.28deg, hslAlpha($color_steelGrey, .5) 0%, hslAlpha($color_gunGrey, .5) 100%);
$gradient_darkAltAlpha: linear-gradient(100.28deg, hslAlpha($color_steelGrey, .75) 0%, hslAlpha($color_gunGrey, .75) 100%);
$gradient_deep: linear-gradient(-45deg, $color_deepBlack 0%, lighten($color_deepBlack, 5%) 100%);
$gradient_deepAlpha: linear-gradient(100.28deg, hslAlpha($color_gunGrey, .75) 0%, hslAlpha($color_deepBlack, .75) 100%);
$gradient_deepAltAlpha: linear-gradient(100.28deg, hslAlpha($color_gunGrey, .95) 0%, hslAlpha($color_deepBlack, .95) 100%);
$gradient_light: linear-gradient(180deg, $color_white 0%, $color_lightGrey 100%);
$gradient_light-reverse: linear-gradient(180deg, $color_lightGrey 0%, $color_white 100%);
$gradient_fadedPurple-reverse: linear-gradient(180deg, $color_pastelPurple 0%, $color_black 100%);
$gradient_lightGrey: linear-gradient(160deg, $color_lighterGrey 0%, $color_lighterGrey 100%);

// BOX-SHADOW
$default_card_boxshadow: 0 rem(10) rem(20) rgba($color_black, 0.1);
$menu_boxshadow: 0 rem(5) rem(10) rgba($color_black, 0.01);
$article_highlight: 0 0 0 rem(20) hslAlpha($color_orange, 0.3);
