.bs-send-badge {
  $send: &;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__wrap {
    overflow-y: auto;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(10);
    padding: rem(20) rem(20) rem(15);
    background: hslAlpha($color_cloudGrey, .2);
  }

  &__title {
    margin: 0;
    line-height: rem(24);
    font-size: rem(18);
    color: $color_powderGrey;

    &--warning {
      color: $color_orange;
    }
  }

  &__user-warning {
    margin: rem(32) rem(16);
    font-size: rem(16);
    color: $color_darkRed;
    text-align: center;
  }

  &__user {

  }

  &__options {
    padding: rem(5) rem(20);

    &__item {
      margin: rem(10) 0;
      border-radius: rem(26);
      transition: all .3s ease 0s;

      &--with-options {
        background: hslAlpha($color_cloudGrey, .2);
      }
    }
  }

  &__selector {
    $selector: &;
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(12);
    padding: 0 rem(24);
    width: 100%;
    min-height: rem(52);
    background: $color_darkOrange;
    border: none;
    border-radius: rem(25);
    box-shadow: 0 rem(4) rem(12) rem(1) hslAlpha($color_deepBlack, .12);
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 500;
    color: $color_powderGrey;
    transition: all .3s ease 0s;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: rem(-1);
      right: rem(-1);
      top: rem(-1);
      bottom: rem(-1);
      z-index: rem(-1);
      background: $color_white;
      border-radius: rem(26);
      transition: all .3s ease 0s;
    }

    &__icon {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: rem(32);
      height: rem(32);

      svg {
        height: auto;
      }
    }

    &__text {
      position: relative;
      z-index: 1;
    }

    &__counter {
      position: absolute;
      right: rem(24);
      font-weight: 700;
      transition: all .3s ease 0s;
    }

    &__check {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 auto;
      width: rem(24);
      height: rem(24);
      color: $color_white;
      opacity: 0;
      transform: translateX(rem(6));
    }

    &:hover {
      color: $color_darkOrange;
      filter: brightness(1.3);
    }

    &--active {
      box-shadow:
        0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12),
        0 0 0 rem(3) $color_darkOrange;
      color: $color_darkOrange;

      &::before {
        right: rem(48);
      }

      #{$selector}__counter {
        opacity: 0;
      }

      #{$selector}__check {
        opacity: 1;
      }
    }

    &--disabled {
      opacity: .3;
      cursor: not-allowed;
    }
  }

  &__points {
    margin: rem(5) 0;
    padding: rem(5) 0 rem(10);

    label {
      margin: 0 rem(5) 0 rem(25);
      display: flex;
      justify-content: space-between;
      gap: rem(12);
    }
  }

  &__action {
    padding: rem(15) rem(20) rem(20);
    background: hslAlpha($color_cloudGrey, .2);

    &__buttons {
      position: relative;
    }

    &__message-button {
      padding: rem(12) 0;
      width: rem(52);
      height: rem(52);

      &.MuiButtonBase-root {
        position: absolute;
        z-index: 20;
        background: $gradient_orangeLight;
        width: rem(52);
        border: 4px solid #f4f4f4;
      }

      &--active {
        transform: translateX(240px);
      }
    }

    &__message-icon {
      display: flex;
    }

    &__message-input {

      .MuiInputBase-input  {
        font-size: rem(16);
        font-weight: 400;
        padding-right: rem(60);

        &::placeholder {
          font-size: rem(16);
          font-weight: 400;
        }
      }

      width: 100%;
    }

    &__message-info {
      position: absolute;
      bottom: rem(2);
      right: rem(46);
      padding-right: rem(12);
      margin: 0;
      color: $color_powderGrey;
      font-size: rem(12);
      text-align: right;
    }
  }
}

.bs-theme--dark .bs-send-badge {

  &__title {
    color: $color_white;
  }

  &__selector {
    background: $gradient_orange;
    color: $color_white;

    &::before {
      background: $gradient_dark;
    }
  }

  &__header {
    background: $gradient_deepAltAlpha;
  }

  &__options {
    background: $gradient_darkAltAlpha;
  }

  &__action {
    background: $gradient_deepAltAlpha;
  }
}
