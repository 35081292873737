.bs-badge {
  $badge: &;
  position: relative;
  z-index: 0;
  display: inline-flex;
  padding: rem(8) rem(16);
  border-radius: rem(24);
  min-height: rem(48);
  width: 80vw;
  background: hslAlpha($color_cloudGrey, .25);

  @include screenSize(tablet) {
    width: auto;
  }
  
  &__icon {
    position: relative;
    width: rem(32);
    height: rem(32);
    justify-content: center;
    display: flex;

    svg {
      position: relative;
      z-index: 1;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__extra-gif {
    position: absolute;
    top: rem(-18);
    left: rem(-18);

    img {
      width: rem(50);
      height: rem(50);
    }
  }

  &__label {
    margin: 0 0 0 rem(10);
    padding: rem(4) 0;
    line-height: rem(24);
    font-size: rem(16);
    font-weight: 500;
    color: $color_powderGrey;
  }

  &__count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20);
    height: rem(20);
    top: rem(-8);
    right: 0;
    font-size: rem(14);
    border-radius: 50%;
    background-color: $color_powderGrey;
    color: $color_white;
    font-weight: 600;
    text-align: center;
  }

    
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: rem(-2);
    left: rem(-2);
    right: rem(-2);
    bottom: rem(-2);
    padding: rem(2);
    border-radius: rem(28);
    -webkit-mask: 
       linear-gradient(black 0 0) content-box, 
       linear-gradient(black 0 0);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;
  }

  &--icon-only {
    padding: rem(7);
    border-radius: 50%;
    min-height: rem(46);

    &::before {
      border-radius: 50%;
    }
  }

  &--Colleague {
    
  }

  &--HR {
    border: rem(3) solid hslAlpha($color_darkPurple, .40);
  }

  &--Teamlead {
    border: rem(3) solid hslAlpha($color_orange, .40);
  }

  &--Management {
    border: rem(3) solid hslAlpha($color_orange, .70);
  }

  &--CEO {
    background-color: hslAlpha($color_orange, .70);
    border: rem(3) solid hslAlpha($color_orange, .70);
  }

  &--user::before {
    background: $gradient_purple;
  }

  &--special::before {
    background: $gradient_orange;
  }

  &--system::before {
  }

  &--medal {
    color: white;
    padding-left: rem(10);

    #{$badge}__icon {
      &::before {
        content: '';
        position: relative;
        background: radial-gradient(circle, $color_orange 0%, $color_darkOrange 100%);
        left: 0;
        top: 0;
      }

      .bs-medal-flare {
        fill: currentcolor;
        position: absolute;
        z-index: 0;
        color: $color_darkOrange;
        top: 0;
        left: 0;
        transform: scale(1.8);
      }
    }

    #{$badge}__label {
      margin-left: rem(20);
    }
  }
}

.bs-theme--dark .bs-badge {
  background: hslAlpha($color_cloudGrey, .125);

  .bs-badge__label {
    color: $color_white;
  }

  .bs-badge__count {
    background-color: $color_white;
    color: black;
  }
}
