.bs-profile-tag {
  $profile: &;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: rem(12);
  background: hslAlpha($color_cloudGrey, .25);
  border-radius: rem(26);

  &__avatar {
    padding: rem(2);
    width: rem(52);
    height: rem(52);
    border-radius: 50%;
    background: $gradient_fadedPurple;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    object-fit: cover;

    &--placeholder {
      padding: rem(8);
      width: auto;
      height: auto;
      background: hslAlpha($color_white, .95);
      border-radius: 50%;
      object-fit: contain;
    }
  }

  &__info {
    flex: 1;
    padding: rem(16) rem(16) rem(16) 0;
  }

  &__name {
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 700;
    color: $color_powderGrey;
  }

  &--big {
    gap: rem(16);
    border-radius: rem(35);

    #{$profile}__avatar {
      width: rem(64);
      height: rem(64);
    }

    #{$profile}__image {
      width: rem(60);
      height: rem(60);
    }

    #{$profile}__info {
      padding: rem(16) rem(24) rem(16) 0;
    }

    #{$profile}__name {      
      line-height: rem(24);
      font-size: rem(18);
    }
  }

  &--small {
    gap: rem(10);
    border-radius: rem(18);

    #{$profile}__avatar {
      width: rem(36);
      height: rem(36);
    }

    #{$profile}__image {
      width: rem(32);
      height: rem(32);
    }

    #{$profile}__info {
      padding: rem(9) rem(16) rem(9) 0;
    }

    #{$profile}__name {      
      line-height: rem(18);
      font-size: rem(14);
    }
  }
}

.bs-theme--dark .bs-profile-tag {
  background: $gradient_dark;

  &__avatar {
    background: $gradient_dark;
  }

  &__image {

    &--placeholder {
      background: $gradient_dark;
    }
  }

  &__name {
    color: $color_white;
  }
}
