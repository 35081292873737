.bs-menu {
  $menu: &;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: rem(5);

  li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__item {
    position: relative;

    &:not(#{$menu}__item--active):hover {

      .bs-menu__link span {
        color: $color_orange;
      }
    }

    .MuiButtonBase-root {
      margin: 0;
      border-radius: rem(30) !important;
    }

    &:hover {

      i {
        color: $color_darkOrange;
      }
    }

    &--active {

      #{$menu}__link {
        padding: rem(12) rem(16);
        background: $color_white;
        box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);

        span {
          display: inline-block;
          background-color: $color_orange;
          background-image: linear-gradient(98deg, $color_orange 0%, $color_darkOrange 100%);
          background-size: 100%;
          font-weight: 700;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }

        i {
          background: hslAlpha($color_powderGrey, .1);
          color: $color_darkOrange;
        }
      }
    }
  }

  .MuiButtonBase-root {
    margin: 0 rem(8);
  }

  &__link {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(12) rem(16);
    min-width: rem(48);
    min-height: rem(48);
    border: none;
    border-radius: rem(24);
    text-decoration: none;
    text-align: right;
    cursor: pointer;
    transition: all .3s ease 0s;

    span {
      color: $color_powderGrey;
      line-height: rem(24);
      font-size: rem(18);
      font-weight: 500;
    }

    i {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: rem(-3);
      margin-left: rem(10);
      width: rem(30);
      min-width: rem(30);
      height: rem(30);
      border-radius: 50%;
      background: hslAlpha($color_powderGrey, .07);
      color: hslAlpha($color_powderGrey, .7);
      
      svg {
        width: rem(20);
      }
    }
  }
}

.bs-theme--dark .bs-menu {

  &__item {

    &--active {

      .bs-menu__link {
        background: $gradient_dark;
      }
    }
  }

  &__link {

    span {
      color: $color_white;
    }
  }
}
