.bs-dialog {

  &__close {
    position: fixed;
    right: rem(20);
    top: rem(20);
    transform: rotate(45deg);
    cursor: pointer;

    svg {
      width: rem(24);
      height: rem(24);
    }

    path {
      transition: all .2s ease 0s;
    }

    &:hover path {
      fill: $color_orange
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(10);
    padding: rem(20) rem(20) rem(15);
    background: hslAlpha($color_cloudGrey, .2);

    h3 {
      margin: 0;
      font-size: rem(22);
      color: $color_powderGrey;
    }
  }

  &__content {
    position: relative;
    padding: rem(20);
  }

  &__field {
    margin: 0 0 rem(18);

    & > * {
      width: 100%;
    }
  }

  &__legend {
    margin: rem(6) 0;
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem(10);
    padding: rem(15) rem(20) rem(20);
    background: hslAlpha($color_cloudGrey, .2);

    &__spacer {
      width: 100%;
      flex: 1;
    }
  }
}

.MuiDialog-root {
  $dialog: &;

  .MuiBackdrop-root {
    backdrop-filter: blur(rem(5));
  }

  .MuiDialog-paper {
    min-width: rem(300);
    background: $color_white;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);
  }
}

.bs-theme--dark .MuiDialog-root {

  .MuiDialog-paper {
    background: transparent;
  }

  .bs-dialog__header {
    background: $gradient_deepAltAlpha;

    h3 {
      color: $color_white;
    }
  }

  .bs-dialog__content {
    background: $gradient_darkAltAlpha;
  }

  .bs-dialog__action {
    background: $gradient_deepAltAlpha;
  }
}
