.bs-theme--dark .fr {
  
  &-toolbar,
  &-second-toolbar,
  &-element {
    background: $color_deepBlack;
    border: none;
    color: $color_white;

    .fr-command.fr-btn {
      color: $color_white;

      &:hover {
        background: $gradient_orange;
      }

      &.fr-open,
      &.fr-open:not(:hover):not(:focus):not(:active) {
        background: $color_black;
      }

      svg path {
        fill: $color_white;
      }
    }
  }

  &-toolbar .fr-more-toolbar {
    background: $color_black;
  }

  &-box.fr-basic {

    .fr-element {
      color: $color_white;
    }

    .fr-wrapper {
      border: none;
    }
  }
}

.fr-popup {

  &.fr-active {
    z-index: 100 !important;
  }
}

.fr-view {
  * {
    font-family: map-get($typo, "font"), sans-serif;
    font-size: rem(map-get($typo, "size"));
  }

  h1 {
    margin: rem(12) 0;
    padding: 0 rem(20);
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.32;
    color: $color_powderGrey;
  }

  h2 {
    margin: rem(10) 0 rem(8);
    padding: 0 rem(20);
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.32;
    color: $color_fadedPurple;
  }

  h3 {
    margin: rem(8) 0 rem(4);
    padding: 0 rem(20);
    font-size: rem(18);
    font-weight: 700;
    line-height: 1.32;
    color: $color_powderGrey;
    text-transform: uppercase;
  }

  h4 {
    margin: 0 0 rem(4);
    padding: 0 rem(20);
    font-size: rem(16);
    font-weight: 700;
    line-height: 1.32;
    color: $color_powderGrey;
  }

  p {
    color: $color_powderGrey;
    margin: 0 0 rem(4) 0;
    font-size: rem(16);
    line-height: 1.32;
  }

  ul, ol {
    color: $color_powderGrey;
    margin: 0 0 rem(4);
    padding: 0 rem(20);
    font-size: rem(16);
    line-height: 1.32;

    li {
      color: $color_powderGrey;
      margin: 0 0 rem(4) rem(20);
    }
  }

  a {
    color: $color_darkOrange;

    &:hover {
      color: $color_orange;
    }
  }
}

.bs-theme--dark .fr-view:not(.bs-article__content--hs-article){
  h1 {
    color: $color_lightGrey;
  }

  h2 {
    color: $color_lightGrey;
  }

  h3 {
    color: $color_lightGrey;
  }

  h4 {
    color: $color_lightGrey;
  }

  p {
    color: $color_lightGrey;
  }

  ul, ol {
    color: $color_lightGrey;
    li {
      color: $color_lightGrey;
    }
  }

  a {
    color: $color_darkOrange;

    &:hover {
      color: $color_orange;
    }
  }
}