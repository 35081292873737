// base sendable badges behaviour
.bs-sendable-badges {
  opacity: 0;
  background: $color_white;
  box-shadow: $default_card_boxshadow;
  border-radius: rem(16);
  margin: rem(36) auto;
  max-width: rem(86);
  display: flex;
  justify-content: center;
  transition: 0.3s all ease-in-out;

  &--visible {
    opacity: 1;
  }

  &__badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(12) 0;
    position: relative;

    &__badge-icon {
      z-index: 1;

      svg {
        top: rem(2);
        position: relative;

        width: rem(24);
        height: rem(24);
      }
    }

    &__badge-donut {
      position: absolute;
      width: rem(62);
      height: rem(62);
      transform: rotate(-90deg);
    }

    &:first-of-type {
      padding-top: rem(16);
    }

    &:last-of-type {
      padding-bottom: rem(16);
    }

    .hint {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, width 0.3s ease-in-out;
      transform: translateX(rem(-40));
      position: absolute;
      z-index: 0;
      left: rem(44);
      width: rem(63);
      opacity: 0;
      padding: rem(12) 0;
      background: $color_light_orange;
      color: $color_darkOrange;
      font-size: rem(15);
      border-radius: 0 rem(16) rem(16) 0;
      text-align: right;
      padding-right: rem(16);
    }

    &:hover {
      @include screenSize(tablet) {
        .hint {
          opacity: 1;
          transform: translateX(rem(-30));
          width: rem(83);
        }
      }
    }
  }
}

// sidebar
.bs-sidebar {
  .bs-sendable-badges-wrapper {
    max-width: rem(240);
    opacity: 0.6;
    transition: opacity 0.1s ease-in;

    &:hover {
      opacity: 1;
    }
  }

  p {
    position: relative;
    width: 100%;
    transform: translateY(rem(30));
    padding: 0;
    color: $color_powderGrey;
    font-size: rem(12);
    text-align: center;
  }
}

// profile page
.bs-colleague {

  .bs-sendable-badges-wrapper {
    max-width: 100%;

    p {
      display: none;
    }
    .bs-sendable-badges {
      max-width: 100%;
      margin: 0;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: rem(16);
      }
      &__badge-container {
        padding: rem(16);

        .hint {
          opacity: 1;
          padding: rem(30) 0 rem(10) 0;
          border-radius: rem(16);
          text-align: center;
          transform: translateX(rem(-46)) translateY(rem(28));
        }

        @include screenSize(tablet) {
          .hint {
            opacity: 0;
            padding: rem(30) 0 rem(10) 0;
            border-radius: rem(16);
            text-align: center;
            transform: translateX(rem(-46));
            width: rem(60);
          }

          &:hover {
            .hint {
              opacity: 1;
              transform: translateX(rem(-46)) translateY(rem(28));
            }
          }
        }
      }

    }
  }
}

.bs-theme--dark .bs-sendable-badges {
  background: hslAlpha($color_cloudGrey, .12);
}
