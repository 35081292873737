.MuiBackdrop-root {
  backdrop-filter: blur(rem(5));
}

.MuiAutocomplete-paper {
  min-width: rem(200);
  border-radius: rem(16);

  .MuiAutocomplete-listbox {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiAutocomplete-groupLabel {
    top: 0;
    padding-top: rem(6);
    padding-bottom: rem(6);
    background: $gradient_purple;
    color: $color_white;
    line-height: initial;
    font-size: rem(14);
    font-weight: 700;
  }

  .MuiAutocomplete-option {

  }

  .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
    background: hslAlpha($color_purple, .2);
  }

  .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused {
    background: hslAlpha($color_purple, .3);
  }
}