.bs-news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $color_white;
  border-radius: rem(18);
  box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);

  @include screenSize(tablet) {
    flex-direction: row;
  }

  &__photo {
    position: relative;
    background: hslAlpha($color_black, .05);
    border-radius: rem(18) rem(18) 0 0;
    font-size: 0;
    overflow: hidden;

    @include screenSize(tablet) {
      flex: 1;
      border-radius: rem(18) 0 0 rem(18);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem(20);

    @include screenSize(tablet) {
      flex: 1;
    }

    &__title {
      padding-bottom: rem(16);
      font-size: rem(22);
      font-weight: 700;
      line-height: rem(28);
      color: $color_deepBlack;
    }

    &__text {
      font-size: rem(16);
      line-height: initial;
      color: $color_powderGrey;
    }

    &__tag {
      display: flex;
      gap: rem(10);
      margin: auto 0 0;
      padding: rem(20) 0;

      @include screenSize(tablet) {
        padding: rem(20) 0 0;
      }

      .bs-tag {
        text-transform: capitalize;
      }
    }
  }

  &__uploader {
    position: absolute;
    left: rem(20);
    bottom: rem(-20);
  }

  &--generated {
    max-height: rem(220);
  }

  &--pinned {
    box-shadow: $article_highlight;
  }
}

.bs-news-card-editor {
  margin-top: rem(-60);
  border: 1px solid $color_cloudGrey;
  border-top: none;
  padding: rem(32) rem(20) rem(10);
  border-radius: 0 0 rem(16) rem(16);
  display: flex;
  justify-content: flex-end;

  button {
    background: none;
    box-shadow: none;
    opacity: 0.6;
    transition: all 0.3s ease-in;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
}

.bs-theme--dark .bs-news-card {
  background: hslAlpha($color_cloudGrey, .12);

  .bs-news-card {

    &__content {
      &__title {
        color: $color_white;
      }

      &__text {
        color: $color_cloudGrey;
      }
    }
  }
}
.bs-theme--dark {
  .bs-news-card-editor {
    border: 1px solid $color_powderGrey;
    border-top: none;
  }
}
