.bs-innovation-menu {
  margin: 0 rem(-40);
  padding: rem(30);
  box-shadow: $default_card_boxshadow;

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include screenSize(tablet) {
      flex-direction: row;
    }

    &__item {
      display: flex;
      align-self: center;
      padding: rem(6) 0;
      width: auto;
      font-size: rem(18);
      font-weight: 800;
      color: $color_orange;
      text-align: center;
      cursor: pointer;

      @include screenSize(tablet) {
        max-width: rem(160);
        padding: 0;
      }

      &:hover {
        transform: scale(1.1);
        transition: all .2s ease-in-out 0s;
      }
    }
  }
}
