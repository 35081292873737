.bs-logo {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: rem(120);
  height: rem(120);
  background: $gradient_orange;
  border-radius: 50%;
  cursor: pointer;
}
