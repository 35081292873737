.bs-file-input {
  $input: &;
  display: flex;

  &--small{
    &.MuiInputBase-input{
      font-size: rem(8);
    }
  }

  &__selector-and-feedback {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 rem(24);
    width: 100%;
    min-height: rem(52);
    background: hslAlpha($color_white, .1);
    border: none;
    border-radius: rem(26);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
    color: $color_powderGrey;
    line-height: rem(48);
    cursor: pointer;
  }

  &__note {
    margin: rem(20) 0 rem(30);
    padding: 0 rem(20);
    font-size: rem(16);
    opacity: .5;

    &--warning {
      margin: rem(10) 0;
      color: $color_red;
      opacity: 1;
    }
  }

  &__preview {
    max-width: rem(240);
    max-height: rem(240);
    border-radius: rem(20);
    margin-left: rem(25);
  }

  &__hidden {
    display: none;
  }

  &--selected #{$input}__input {
    box-shadow:
      0 0 0 rem(1) $color_darkGreen,
      inset 0 0 0 rem(1) $color_darkGreen,
      0 rem(4) rem(12) 0 hslAlpha($color_darkGreen, .12);
  }
}

.bs-theme--dark .bs-file-input {

  &__input {
    color: $color_white;
  }
}
