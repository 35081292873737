.bs-theme {

  &--light {

  }

  &--dark {
    background: $color_deepBlack;
  }
}

