.bs-appbar {
  $appbar: &;
  padding: rem(16) rem(20);
  padding-right: rem(12);
  max-width: rem(240);
  
  &--sticky {
    position: fixed;
    padding: rem(16 - 10) rem(12) rem(16) rem(20);
  }
}
