.bs-article {

  &__info {
    margin: rem(24) 0;
    display: flex;
    gap: rem(24);
    flex-wrap: wrap;
  }

  &__back {
    text-transform: lowercase;

    svg {
      height: rem(16);
    }
  }

  &__cover {
    margin: rem(24) 0;
    width: 100%;
    min-height: rem(36);
    max-height: rem(360);
    background: hslAlpha($color_black, .05);
    border-radius: rem(12);
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__title {
    margin: rem(24) 0;
    font-weight: 700;
    font-size: rem(28);
    line-height: rem(36);

    @include screenSize('tablet') {
      font-size: rem(42);
      line-height: rem(56);
    }
  }

  &__content {
    margin: rem(24) 0;

    p {

    }

    ul {

    }

    li {

    }

    a {

    }

    img {
      max-width: 100%;
      border-radius: rem(12);
      border: none;
      outline: none;
    }

    &--generated {
    }
  }

  &__generated-content {
    section {
      display: flex;
      flex-flow: wrap;
    }

    &__colleague-list {
      width: 50%;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        color: $color_orange;
        padding: rem(8);

        &:hover {
          color: $color_darkOrange;
        }
      }

      img {
        width: rem(32);
        border-radius: 50%;
        margin-right: rem(16);
        position: absolute;
      }

      em {
        font-weight: 400;
        font-style: normal;
        padding-left: rem(44);
      }
    }


    &__birthdays {
      margin-top: rem(32);
    }
    &__anniversaries {
      margin-top: rem(32);
      h4 {
        width: 100%;
        padding-top: rem(16);
      }
    }

    &__newbies {
      margin-top: rem(32);
    }

    &__sub-header {
      margin: 0;
    }
  }
}
