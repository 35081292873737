.bs-projects {

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(8);
    padding: rem(20) 0;

    @include screenSize(tablet) {
      gap: rem(16);
      justify-content: flex-start;
      align-items: flex-start;
    }

    .bs-button--filter {
      margin-left: auto;
    }

    &__options {
      position: relative;
      margin: 0 rem(-20);
      width: calc(100% + rem(20) + rem(20));
      max-height: 0;
      overflow: hidden;
      transition: all .5s ease 0s;

      &__box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: rem(16);
        padding: rem(20);
        background: hslAlpha($color_black, .03);

        & > .bs-input {
          min-width: 32%;
          width: 100%;

          @include screenSize(tablet) {
            min-width: 32%;
            width: auto;
          }
        }
      }

      &--active {
        max-height: rem(300);
      }
    }
  }
}