.bs-news-feed {
  width: 100%;

  &__list {
    position: relative;
    padding: 0 rem(10) rem(10);

    @include screenSize(tablet) {
      padding: 0 rem(20) rem(20);
    }
  }

  &__item {
    display: block;
    margin: rem(20) 0 rem(40);
    text-decoration: none;

    @include screenSize(tablet) {
      margin: rem(20) 0 rem(40);
    }
  }

  &__draft {
    margin: rem(40 + 20) 0;
    background: hslAlpha($color_orange, 0.3);
    border-radius: rem(18);
    box-shadow: $article_highlight;

    .bs-news-feed__item {
      margin: rem(20) 0;
    }
  }

  &__note {
    display: flex;
    gap: rem(12);

    &__icon {
      margin: 0 0 0 rem(12);
      width: rem(18);
      height: rem(18);
    }

    &__text {
      font-weight: 700;
      text-transform: uppercase;
      color: $color_purple;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: rem(12);
    padding-top: rem(12);

    @include screenSize(tablet) {
      padding-top: 0;
    }
  }
}

.bs-theme--dark .bs-news-feed {

  &__draft {
    background: hslAlpha($color_black, .2);
    box-shadow: 0 0 0 rem(20) hslAlpha($color_black, .2);
  }
}