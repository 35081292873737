.bs-faq-category-selector {
  $category-selector: &;
  transition: all .3s ease 0s;

  &.MuiButtonBase-root {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(8) rem(16);
    min-width: rem(50);
    min-height: rem(50);
    background: $color_white;
    border-radius: rem(25);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 400;
    color: $color_powderGrey;
    cursor: pointer;

    @include screenSize(tablet) {
      padding: rem(12) rem(24);
    }

    &#{$category-selector}--active {
      background: $gradient_purple;
      box-shadow: 0 0 0 0 hslAlpha($color_deepBlack, 0);
      color: $color_white;
    }

    &#{$category-selector}--sub {
      padding: rem(5) rem(24);
      min-width: rem(40);
      min-height: rem(40);
      line-height: rem(22);
      font-size: rem(18);
    }
  }

  &:hover {
    color: $color_purple;
    filter: brightness(1.3);
  }
}

.bs-theme--dark .bs-faq-category-selector {

  &.MuiButtonBase-root {

    &.bs-faq-category-selector--inactive {
        background: hslAlpha($color_cloudGrey, .125);
        color: $color_white;
    }
  }
}
