.bs-sidebar {
  $sidebar: &;
  display: none;
  position: relative;
  padding: rem(16) rem(20);
  width: 100%;
  height: 100%;

  @include screenSize(tablet) {
    display: block;
  }

  &__actions {

    &__item {
      margin: rem(10) 0;
      width: 100%;

      & > * {
        width: 100%;
      }
    }
  }
}
