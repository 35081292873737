@keyframes indicatorFlowing {
  0% {
    transform: translateY(rem(3));
  }
  50% {
    transform: translateY(rem(-3));
  }
  100% {
    transform: translateY(rem(3));
  }
}

.bs-faq {
  $faq: &;
  padding: rem(20) rem(10);

  &__title {
    margin: rem(12) 0 rem(18);
    text-align: center;

    @include screenSize(tablet) {
      text-align: left;
    }
  }

  &__subtitle {
    font-size: rem(20);
    text-align: center;
    color: $color_powderGrey;
    font-weight: 500;

    @include screenSize(tablet) {
      font-size: rem(24);
      text-align: left;
    }
  }

  &__selector {
    margin: 0 0 rem(30);
    padding: 0 0 rem(30);
    border-bottom: rem(1) solid hslAlpha($color_deepBlack, .1);
  }

  &__indicator {
    display: flex;
    justify-content: center;

    &__box {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: rem(12);
      margin: rem(-30) 0 0;
      padding: rem(12) rem(18);
      background: $gradient_dark;
      border-radius: 0 0 rem(22) rem(22);
      box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .2);
      color: $color_white;
      line-height: rem(20);
      cursor: default;
    }

    &__icon {
      animation: indicatorFlowing 2s infinite ease-in-out;
    }

    &__text {
      font-size: rem(18);
    }
  }

  &__search {
    padding: rem(10) 0 rem(55);

    .bs-input {
      @include screenSize(tablet) {
        width: rem(735);
      }

      &.MuiInputBase-root.MuiInputBase-adornedEnd {
        padding-right: 0;
      }
    }
  }

  &__topics {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);
    padding: rem(12) 0 0;

    #{$faq}__topic {

    }
  }

  &__result {
    margin: rem(30) 0 0;

    &__header {
      margin: rem(10) 0 rem(20);
      display: flex;
      justify-content: space-between;
    }

    &__back {

    }

    &__title {
      font-size: rem(20);
      text-align: center;
      color: $color_powderGrey;
      font-weight: 500;

      .bs-button {
        display: none;
      }

      @include screenSize(tablet) {
        font-size: rem(24);
        text-align: left;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .bs-button {
          display: flex;
        }
      }
    }

    &__share.bs-button {
      display: flex;

      @include screenSize(tablet) {
        display: none;
      }
    }

    &__item {
      display: block;
      margin: rem(20) 0;
      color: $color_powderGrey !important;

      .bs-faq__result__title {
        margin: 0 rem(10) rem(6);
        font-weight: 700;
      }

      .bs-faq__result__content {
        margin: 0;
      }

      .search-highlight {
        color: $color_purple !important;
        font-size: inherit;
      }

      &:first-of-type {
        margin: rem(30) 0 0;
      }
    }

    &--list {
      margin: 0;
    }

    &--single {
      margin: 0;

      #{$faq}__result__title {
        margin: rem(36) rem(10) rem(10);
        font-size: rem(30);
        font-weight: 700;
      }
    }
  }

  &__top-button {

    &.bs-button.MuiButtonBase-root.bs-button--small {
      margin: rem(30) 0;
    }
  }
}

.bs-theme--dark .bs-faq {

  &__result {

    &__title {
      color: $color_white;
    }

    &__content {

      * {
        color: $color_white !important;
      }

      a {
        color: $color_orange !important;
      }
    }

    &__item {
      color: $color_white !important;

      .search-highlight {
        color: $color_purple !important;
      }
    }
  }

  &__selector {
    border-bottom: rem(1) solid hslAlpha($color_black, .3);
  }
}
