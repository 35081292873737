.cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after{
    clear:both;
  }
}

.hidden {
  display: none;
}

.not-visible {
  opacity: 0;
}

.bs-content-width {
  max-width: rem(960);
}

.bs-fallback-avatar {
  padding: 10%;
}

.bs-helper {

  &--disabled {
    opacity: .5;
    pointer-events: none;
    transition: all .2s ease 0s;
    filter: grayscale(1);
    cursor: not-allowed;

    * {
      cursor: not-allowed !important;
    }
  }
}

.bs-helper-mobile-icon {

  .text {
    display: none;

    @include screenSize(tablet) {
      display: initial;
    }
  }

  .icon {
    display: initial;

    &--in-tag {
      margin: rem(-7);
    }

    @include screenSize(tablet) {
      display: none;
    }
  }
}
