@keyframes loaderFlashingDot {
  0% {
    background-color: hslAlpha($color_orange, .25);
  }
  50% {
    background-color: $color_orange;
  }
  100% {
    background-color: hslAlpha($color_orange, .25);
  }
}

@keyframes successCheck {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.bs-tech-tag {
  $techTag: &;
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: rem(36);
  background: hslAlpha($color_cloudGrey, .25);
  border-radius: rem(18);
  transition: all .2s ease 0s;

  &__tech,
  &__level {
    position: relative;
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 400;
  }

  &__tech {
    padding: 0 rem(10) 0 rem(15);
    color: $color_powderGrey;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: rem(1);
      background: hslAlpha($color_deepBlack, .3);
      transition: all .5s ease 0s;
    }
  }

  &__level-group {
    display: inline-flex;
    align-items: center;
  }

  &__level {
    position: relative;
    display: inline-block;
    padding: rem(6) rem(4);
    border-radius: rem(15);
    color: $color_orange;
    cursor: pointer;
    transition: all .2s ease 0s;

    @include screenSize(tablet) {
      padding: rem(6) rem(10);
    }

    &:last-of-type {
      margin: 0 rem(5) 0 0;
    }

    &--selected {
      font-weight: 700;
    }

    &--icon {
      margin: 0;
      padding: rem(4) rem(10) 0 rem(10);

      svg {
        font-size: rem(16);
        height: rem(24);
        color: $color_darkRed;
      }
    }
  }

  .bs-input.MuiAutocomplete-root {
    align-items: center;
    margin-right: rem(-5);
    min-height: auto;
    height: rem(36);
    line-height: rem(36);
    box-shadow: none;

    &::before {
      position: absolute;
    }

    .MuiAutocomplete-inputRoot {
      padding: 0 rem(30) 0 rem(15);

      .MuiAutocomplete-input {
        padding: 0;
        min-width: rem(90);
        font-size: rem(16);
      }
    }
  }

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem(36);
    height: rem(36);
    border-radius: rem(18);
    background: hslAlpha($color_cloudGrey, .25);
    cursor: pointer;
    transition: all .2s ease 0s;

    svg {
      fill: $color_powderGrey;
      transition: all .2s ease 0s;
    }

    &:hover {
      background: hslAlpha($color_white, .5);
      box-shadow: 0 0 rem(6) 0 hslAlpha($color_black, .2);

      svg {
        fill: $color_orange;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: hslAlpha($color_white, .25);
    backdrop-filter: blur(rem(5));
    border-radius: rem(18);
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease 0s;
    
    &__indicator {
      position: relative;

      &,
      &::before, 
      &::after {
        display: inline-block;
        width: rem(10);
        height: rem(10);
        border-radius: rem(5);
        background-color: $color_orange;
        color: $color_white;
        animation: loaderFlashingDot .9s infinite ease-in-out;
      }
    
      &::before, 
      &::after {
        content: '';
        position: absolute;
        top: 0;
      }
    
      &::before {
        left: rem(-15);
        animation-delay: 0s;
      }
    
      & {
        animation-delay: .3s;
      }
    
      &::after {
        left: rem(15);
        animation-delay: .6s;
      }
    }

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }


  &__success {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    
    &__bg {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: rem(18);
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1000%;
        height: 100%;
        background: $gradient_orange;
        border-radius: rem(18);
        transform: translateX(-100%);
      }
    }

    &__anim {
      position: relative;
      z-index: 2;
      height: 110%;
    }

    &--active {
      opacity: 1;
      pointer-events: all;

      #{$techTag}__success__bg::before {
        animation: successCheck 3.5s linear 0s forwards
      }
    }
  }

  &--active {
    width: 100%;
    box-shadow: 0 0 0 rem(1) hslAlpha($color_black, .25);

    @include screenSize(tablet) {
      width: auto;
    }

    #{$techTag}__tech::after {
      background: hslAlpha($color_deepBlack, 0);
    }

    #{$techTag}__level {

      &:last-of-type {
        margin: 0 rem(2) 0 0;
      }

      &:hover {
        background: hslAlpha($color_white, .5);
        box-shadow: 0 0 rem(6) 0 hslAlpha($color_black, .15);
      }
    }
  }
}

.bs-theme--dark .bs-tech-tag {
  background: hslAlpha($color_cloudGrey, .12);

  &__tech {
    color: $color_white;
  }

  &__button {

    svg {
      fill: $color_white;
    }

    &:hover {

      svg {
        fill: $color_orange;
      }
    }
  }
}
