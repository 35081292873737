.bs-innovation {
  padding: 0 rem(20);
}

.bs-innovation-header {
  display: flex;
  flex-direction: column;
  margin: 0 rem(-40);
  padding: rem(30);
  background: $gradient_fadedPurple;
  border-radius: rem(20) rem(20) 0 0;

  @include screenSize(tablet) {
    flex-direction: row;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
  }

  &__ninja {
    svg {
      width: 100%;

      @include screenSize(tablet) {
        max-width: rem(500);
      }
    }
  }
}
