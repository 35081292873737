@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@100;300;400;500;700;900&subset=latin-ext&display=swap');

body * {
    font-family: map-get($typo, "font"), sans-serif;
    font-size: rem(map-get($typo, "size"));
}

%fix-bolder-fonts {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, button, input, select, textarea { 
    @extend %fix-bolder-fonts;
}

h1 {
    margin: rem(20) 0;
    line-height: rem(60);
    font-size: rem(48);
    font-weight: 700;
}

h2 {
    margin: rem(16) 0;
    line-height: rem(40);
    font-size: rem(32);
    font-weight: 700;
}

h3 {
    margin: rem(12) 0;
    line-height: rem(30);
    font-size: rem(24);
    font-weight: 700;
}

h4 {
    margin: rem(8) 0;
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 400;
}

a {
    text-decoration: underline;
}

.bs-theme {
    color: $color_black;

    &--dark {
        color: $color_white;
    }
}
