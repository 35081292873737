.bs-theme-switch {
  $themeSwitch: &;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: rem(36);

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: rem(2);
    top: rem(2);
    right: rem(2);
    bottom: rem(2);
    background: $gradient_fadedPurple;
    border-radius: rem(16);
    box-shadow: inset 0 rem(2) rem(4) 0 hslAlpha($color_black, .24);
    transition: all .6s ease 0s;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
    width: 50%;
    min-width: rem(40);
    min-height: rem(36);
    transition: all .3s ease 0s;
  }

  &__theme-light,
  &__theme-dark {
    flex: 1;

    &.MuiButtonBase-root {
      position: relative;
      z-index: 3;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: rem(40);
      min-height: rem(36);
      border-radius: rem(18);
      line-height: rem(22);
      overflow: hidden;
    }
  }

  &__icon-light-light {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5;
    width: rem(20);
    height: rem(20);
    opacity: 0;
    transform: translate3d(rem(-10), rem(-10), 0);
    transition: all .4s ease 0s;
  }

  &--light {

    &:after {
      left: 0;
      background: $color_white;
    }
  }

  &--dark {

    &:before {
      opacity: .25;
    } 

    &:after {
      left: 50%;
      background: $gradient_dark;
    }

    #{$themeSwitch}__icon-light-light {
      opacity: 1;
    }
  }
}
