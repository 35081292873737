.bs-game {
  position: relative;
  padding: rem(30) rem(30) rem(20);

  &__baby {
    position: absolute;
    right: 50%;
    top: rem(20);
    transform: translateX(50%);

    img {
      width: rem(20);
      height: rem(20);
    }
  }

  &__time {
    position: relative;
    width: 100%;
    height: rem(10);
    margin-top: rem(20);
    border: rem(1) solid $color_light_orange;
    border-radius: rem(8);

    &__remaining {
      width: 100%;
      height: rem(8);
      background-color: $color_orange;
      border-radius: rem(8) 0 0 rem(8);
      transition: all 5ms linear;
    }

    &--hidden {
      opacity: 0;
    }

    &--isOver {
      border: rem(1) solid $color_cloudGrey;
    }
  }

  &__photo {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      width: rem(200);
    }
  }

  &__feedback-emoji {
    position: absolute;
    top: rem(-10);

    p {
      font-size: rem(40);
      opacity: 0;
      transform: scale(0.1);
      animation-name: bs-game-emoji, bs-game-opacity;
      animation-duration: 1s;
    }
  }

  &__score {
    text-align: center;
  }

  &__options {
    display: flex;
    flex-direction: column;
    padding: rem(20);

    button {
      margin: rem(8) 0;
    }
  }

  &__option {
    padding: rem(6) rem(12);
    margin: rem(6) 0;
    text-align: center;
    border: rem(1) solid $color_cloudGrey;
    border-radius: rem(16);
    cursor: pointer;

    &__pic {
      width: rem(30);
      height: rem(30);
      margin-right: rem(10);
    }

    &--selected {
      border: rem(1) solid $color_orange;
    }

    &--correct {
      border: rem(1) solid $color_green;
    }

    &--wrong {
      border: rem(1) solid $color_red;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
  }

  &__game-over {
    text-align: center;

    img {
      max-width: rem(300);
    }

    &__correct {
      color: $color_orange;
      font-size: rem(18);
      font-weight: 600;
    }

    &__points {
      font-size: rem(18);
      font-weight: 600;
    }
  }
}

@keyframes bs-game-emoji {
  0% { transform: scale(0.1) }
  100% { transform: scale(3) }
}

@keyframes bs-game-opacity {
  0% { opacity: 0 }
  20% { opacity: 100 }
  90% { opacity: 100 }
  100% { opacity: 0 }
}
