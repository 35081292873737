.bs-profile-card {
  $profileCard: &;
  position: relative;
  max-width: rem(280);
  text-align: center;
  cursor: pointer;

  @include screenSize(tablet) {
    max-width: rem(200);
  }

  &__box {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: rem(410);
    background: $color_lightGrey;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);
    text-align: left;

    @include screenSize(tablet) {
      height: rem(338);
    }

    & > * {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
    }
  }


  &__content {
    flex: 1;
  }

  &__info {
    position: relative;
    width: 100%;
    background: $color_white;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);
  }

  &__medals {
    position: absolute;
    right: rem(12);
    top: rem(12);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    gap: rem(8);

    &__item {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: rem(32);
      height: rem(32);
      background: $color_white;
      border-radius: rem(16);
      box-shadow: 0 rem(2) rem(6) 0 hslAlpha($color_black, .15);
      background: radial-gradient(circle, $color_orange 0%, $color_darkOrange 100%);

      & > .bs-medal {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        z-index: 1;
      }

      & > .bs-medal-flare {
        fill: currentcolor;
        position: absolute;
        z-index: 0;
        color: $color_darkOrange;
        top: rem(6);
        transform: scale(1.9);
      }

      svg {
        max-width: rem(20);
        max-height: rem(20);
      }
    }
  }

  &__avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient_light;
    border-radius: rem(18);

    @include screenSize(tablet) {
      height: rem(200);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: rem(18);
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.3s ease 0s;

    &--placeholder {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50%;
      height: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__details {
    position: relative;
    padding: rem(12) rem(20);
    height: rem(94);
  }

  &__tech {
    position: absolute;
    right: rem(-12);
    top: rem(-14);
    padding: rem(4) rem(10);
    background: $gradient_purple;
    border-radius: rem(14);
    box-shadow: 0 rem(4) rem(6) 0 hslAlpha($color_black, .12);
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 500;
    color: $color_white;
  }

  &__name {
    margin: 0;
    line-height: rem(26);
    font-size: rem(18);
    font-weight: 700;
    color: $color_deepBlack;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &__position {
    margin: rem(2) 0;
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 400;
    color: $color_powderGrey;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__department {
    display: inline-block;
    margin: rem(4) 0 0;
    background-color: $color_orange;
    background-image: $gradient_orange;
    background-size: 100%;
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 700;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-transform: capitalize;
  }

  &__extra {
    display: flex;
    padding: rem(10) rem(20) rem(16);
    width: 100%;
    min-height: rem(36);

    &--hide {
      display: none;
    }
  }

  &__extra-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__phone {
    margin: 0;
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 400;
    color: $color_powderGrey;
  }

  &__master-position {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: rem(-3) 0 rem(-5) rem(-3);
    padding: rem(6);
    min-width: rem(32);
    min-height: rem(32);
    background: $color_white;
    border-radius: rem(16);
    box-shadow: 0 rem(2) rem(2) 0 hslAlpha($color_black, .12);
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 700;
    font-style: normal;
    color: $color_powderGrey;

    &--top {
      background: $gradient_orange;
      color: $color_white;
    }
  }

  &__master-points {
    margin: rem(2) 0;
    line-height: rem(20);
    font-size: rem(16);
    font-weight: 400;
    color: $color_powderGrey;
  }

  &__send-badge.MuiButtonBase-root {
    position: relative;
    margin: rem(10) 0 0;
    width: 100%;
  }

  &--default {

    #{$profileCard}__extra {
      justify-content: center;
    }
  }

  &--master {

    #{$profileCard}__extra {
      justify-content: space-between;
    }
  }
}

.bs-theme--dark .bs-profile-card {

  &__box {
    background: $color_deepBlack;
  }

  &__info {
    background: $gradient_darkAlpha;
  }

  &__avatar {
    background: $color_deepBlack;
  }

  &__image--placeholder {

  }

  &__name {
    color: $color_white;
  }

  &__position {
    color: $color_cloudGrey;
  }

  &__phone {
    color: $color_cloudGrey;
  }

  &__master-position {
    background: $gradient_darkAlpha;
    color: $color_white;

    &--top {
      background: $gradient_orange;
    }
  }

  &__master-points {
    color: $color_cloudGrey;
  }
}
