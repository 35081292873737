.bs-switch {
  
  .MuiSwitch-switchBase {

    &.Mui-checked {
      color: $color_orange;

      & + .MuiSwitch-track {
        background: $gradient_orange;
      }
  
      &.Mui-disabled,
      &.Mui-disabled + .MuiSwitch-track  {
        color: $color_orange;
        filter: grayscale(1);
      }
    }
  }

  & + span.MuiFormControlLabel-label.Mui-disabled {
    color: inherit 
  }
}