.bs-project-card {
  position: relative;
  background: $color_white;
  border-radius: rem(18);
  box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include screenSize(tablet) {
      flex-direction: row;
    }
  }

  &__photo {
    font-size: 0;

    img {
      height: rem(280);
      width: 100%;
      border-radius: rem(18) rem(18) 0 0;
      object-fit: cover;

      @include screenSize(tablet) {
        width: rem(220);
        border-radius: rem(18) 0 0 rem(18);
      }
    }

    &--open {
      img {
        @include screenSize(tablet) {
          border-radius: rem(18) 0 0 0;
          transition: all 0.2ms ease-in;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 0 rem(15);

    @include screenSize(tablet) {
      padding-left: rem(30);
    }

    &__title {
      color: $color_deepBlack;
      font-size: rem(24);
      font-weight: 700;
      line-height: rem(30);
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: rem(16);

      &__item {
        padding: rem(8) rem(8) 0 0;;
      }
    }

    &__avatars {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: rem(65);

      @include screenSize(tablet) {
        padding-bottom: rem(36);
        padding-right: rem(36);
      }

      &__item {
        padding-right: rem(16);
      }

      &__more {
        display: flex;
        align-items: flex-end;
        padding-bottom: rem(4);
        color: $color_orange;
      }

      &--open {
        padding-bottom: 0;
      }
    }

    &__status {
      padding-bottom: rem(12);
      color: $color_powderGrey;
      font-size: rem(14);
    }

    &__description {
      transition: all 0.3s ease-in;
      margin: 0;
      max-height: 0;
      height: 0;
      overflow: hidden;
      padding: 0;
      p {
        opacity: 0;
        transition: all 0.3s ease-in;
        padding: 0 rem(30);

        @include screenSize(tablet) {
          padding: 0 rem(20);
        }
      }

      &--open {
        padding: rem(30) 0;
        max-height: max-content;
        height: auto;

        p {
          opacity: 1;
        }
      }
    }
  }

  &__accordion-icon {
    position: absolute;
    bottom: rem(20);
    right: rem(20);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transition-delay: 0.1s;
    transform-origin: center center;

    svg {
      padding: rem(6);
      fill: $color_orange;
      border-radius: 50%;
    }

    svg:hover {
      fill: $color_white;
      background-color: $color_orange;
    }

    &--open {
      transform: rotate(180deg);
      bottom: rem(20);
    }
  }
}

.bs-theme--dark .bs-project-card {
  background: hslAlpha($color_cloudGrey, .12);

  .bs-project-card {

    &__content {
      &__title {
        color: $color_white;
      }
    }
  }
}
