.bs-notification-bar {
  $notificationBar: &;
  position: relative;
  margin: 0 0 rem(30);
  padding: rem(16);
  background: $gradient_purple;
  border-radius: rem(18);
  box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);

  &__text {
    margin: 0;
    padding: rem(4);
    line-height: rem(24);
    font-size: rem(18);
    font-weight: 500;
    color: $color_white;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: rem(17);
    margin: rem(13) 0 0;
  }

  &__button {

  }
}

.bs-theme--dark .bs-notification-bar {

}
