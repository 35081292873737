.bs-cv-generator {
  &__title {
    margin: rem(20) 0 rem(20) rem(20);
    padding: rem(10) rem(20);
    border-left: rem(4) solid $color_cloudGrey;
    color: $color_steelGrey;
    font-style: italic;

    em {
      font-weight: bold;
      text-transform: uppercase;
      color: $color_darkOrange;
    }

    a {
      display: block;
      margin: rem(20) 0 0;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    svg {
      width: rem(18);
    }
  }

  &__form-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background: hslAlpha($color_steelGrey, 0.4);
  }
  &__form__modal {
    position: relative;
    z-index: 999;
    background: white;
    padding: rem(16);
    border-radius: rem(16);
  }

  &__flex-list {
    display: flex;
    flex-wrap: wrap;
    padding: rem(10) 0;

    > div {
      margin-right: rem(10);
      margin-bottom: rem(10);
    }
  }

  &__accordion-summary {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin: 0;
    }
    p:first-child {
      font-weight: 500;
      text-transform: uppercase;
    }

    button.bs-button {
      position: absolute;
      right: rem(130);
      top: rem(15);
    }
  }

  &__download-btn {
    margin-left: rem(36);
  }

  p.hint {
    color: $color_gunGrey;
    font-style: italic;
  }
  p.warning {
    color: $color_orange;
    font-style: italic;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    background: $gradient_light-reverse;
  }

  .bs-cv-professional-bg {
    border-radius: rem(10);
    padding: rem(10);
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    background: rgba(0,0,0,0.02);
    margin-bottom: rem(10);

    &__form-data {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 3fr 2fr;
    }

    &__descriptions {
      ul {
        list-style: disc;
        margin-left: rem(20);
        li {
          padding-left: rem(0);
        }
      }
    }
  }

  .professional-backgrounds-grid {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr 2fr;

    p {
      margin: 0;
      font-weight: 700;
      padding: rem(8) 0;
    }
  }

  .project-list-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 2fr;

    &__header {
      p {
        margin: 0;
        font-weight: 700;
        padding: rem(8) 0;
      }
    }
    &__item {
      border-radius: rem(10);
      padding: rem(10);
      box-shadow: 0 0 5px rgb(0 0 0 / 30%);
      background: rgba(0,0,0,0.02);
      margin-bottom: rem(10);
    }
  }

  .course-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 1fr;
    margin-top: rem(10);

    &__header {
      p {
        margin: 0;
        font-weight: 700;
        padding: rem(8) 0;
      }
    }
    &__item {
      border-radius: rem(10);
      padding: rem(10);
      box-shadow: 0 0 5px rgb(0 0 0 / 30%);
      background: rgba(0,0,0,0.02);
      margin-bottom: rem(10);
    }
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  .education-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 2fr;

    &__header {
      p {
        margin: 0;
        font-weight: 700;
        padding: rem(8) 0;
      }
    }
    &__item {
      border-radius: rem(10);
      padding: rem(10);
      box-shadow: 0 0 5px rgb(0 0 0 / 30%);
      background: rgba(0,0,0,0.02);
      margin-bottom: rem(10);
    }
  }

  .invisible_link {
    display: none;
  }

  .bs-admin-cv-item{
    display: flex;
    align-items: center;
    justify-content: space-between
  }
}

.bs-cv-dialog-content {
  padding: rem(24);

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 rem(6);
    }
  }
}

.bs-theme--dark {
  .bs-cv-generator {
    &__title {
      color: $color_light_orange;
    }

    .MuiAccordionSummary-expandIconWrapper {
      svg {
        fill: $color_cloudGreyAlpha;
      }
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
      background: $gradient_fadedPurple-reverse;
    }

    .bs-cv-professional-bg {
      box-shadow: 0 0 5px $color_lightPurple;
      background: $color_pastelPurple_alpha;
    }

    .education-grid {
      &__item {
        box-shadow: 0 0 5px $color_lightPurple;
        background: $color_pastelPurple_alpha;
      }
    }

    .course-grid {
      &__item {
        box-shadow: 0 0 5px $color_lightPurple;
        background: $color_pastelPurple_alpha;
      }
    }

    .project-list-grid {
      &__item {
        box-shadow: 0 0 5px $color_lightPurple;
        background: $color_pastelPurple_alpha;
      }
    }

    p.hint {
      color: $color_light_orange;
      font-style: italic;
    }

    &__form__modal {
      background: $color_pastelPurple_alpha;
    }
  }
}
