.bs-colleague {
  padding: rem(30) rem(20);

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: rem(20);
    padding: 0 0 rem(30);
    width: 100%;

    @include screenSize(tablet) {
      flex-direction: row;
      align-items: flex-start;
    }

    h2 {
      margin: 0;
      font-size: rem(24);
      font-weight: 500;
      color: $color_powderGrey;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    position: relative;

    @include screenSize(tablet) {
      flex-direction: row;
    }

    &__photo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(200);
      height: rem(200);
      margin: 0 auto;
      background-color: $color_white;
      border-radius: rem(18);

      img {
        width: rem(200);
        height: rem(200);
        object-fit: cover;
        border-radius: rem(18);
      }
    }

    &__details {
      flex: 1;
      padding-left: 0;

      @include screenSize(tablet) {
        padding-left: rem(30);
      }

      h2 {
        text-align: center;

        @include screenSize(tablet) {
          text-align: left;
        }
      }
    }

    &__block {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    &__label {
      margin: rem(10) 0;
      color: $color_powderGrey;
      font-size: rem(20);
      line-height: rem(25);
    }

    &__item {
      margin: rem(10) 0;

      &,
      a {
        color: $color_orange;
        font-size: rem(20);
        line-height: rem(25);
      }
    }

    &__actions {
      display: flex;
      justify-content: space-evenly;
      padding: rem(20) 0 0;
    }

    &__disco {
      position: absolute;
      top: rem(-60);
      left: rem(10);

      @include screenSize(tablet) {
        top: rem(-118);
        left: rem(-55);
      }

      img {
        width: rem(70);
      }
    }

    &__stars {
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: rem(60);
      }
    }

    &__star {
      position: absolute;

      &--1 {
        left: rem(-10);
        bottom: rem(-115);

        @include screenSize(tablet) {
          left: rem(-60);
          bottom: rem(-80);
        }
      }

      &--2 {
        left: rem(150);
        top: rem(400);

        @include screenSize(tablet) {
          left: rem(300);
          top: rem(180);
        }
      }

      &--3 {
        left: rem(300);

        @include screenSize(tablet) {
          left: rem(600);
        }
      }
    }

    &--birthweek {

    }
  }

  &__chat {
    flex: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: rem(15);

    @include screenSize(tablet) {
      justify-content: flex-end;
    }

    &__edit {
      cursor: pointer;
    }

    &__options {
      display: flex;
      justify-content: flex-end;
      gap: rem(10);
    }

    &__item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: rem(36);
      height: rem(36);
      opacity: .3;
      filter: grayscale(1);
      cursor: default;

      &--active {
        background: $color_white;
        border-radius: rem(18);
        box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
        opacity: 1;
        filter: grayscale(0);
        cursor: pointer;
      }
    }

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: rem(24);
      height: rem(24);

      svg {
        max-height: 100%;
      }
    }

    &__editor {
      display: flex;
      flex-direction: column;

      &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: rem(8) rem(20);
        margin: 0 rem(-20);
        border-bottom: rem(1) solid $color_cloudGreyAlpha;

        &--unknown {
          .bs-colleague__chat__provider svg {
            filter: grayscale(1);
          }
        }

        .bs-colleague__chat__provider {
          width: rem(32);
          margin-right: rem(16);
        }

        .bs-colleague__chat__value {
          display: flex;
          width: 100%;

          .bs-input, .bs-button {
            flex: 1;
          }
          .bs-button {
            justify-content: left;
            text-align: left;
          }

          &__action {
            justify-content: flex-end;

            @include screenSize(tablet) {
              margin-left: rem(16);
            }
          }
        }
      }

      button.bs-button.MuiButtonBase-root.bs-button--secondary {
        box-shadow: none;
        padding: 0 rem(12);
      }
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: rem(20);
    margin: rem(30) rem(-40) 0;
    padding: rem(20) rem(40);
    background: hslAlpha($color_black, .03);
  }

  &__badges {
    margin: rem(15) 0;
    
    &__header {
      display: flex;
      align-items: center;
      gap: rem(12);
    }
    
    &__title {
      
    }
    
    &__count {
      color: $color_powderGrey;
      font-size: rem(18);
    }
    
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: rem(15);
      padding: rem(10) 0 rem(30);
    }

    &__item {

    }

    &__note {
      padding: rem(8) 0 0;
      font-size: rem(18);
      color: hslAlpha($color_powderGrey, .75);
    }
  }

  &__notifications {
    padding: rem(15) 0;
  }

  &__techs {
    margin: rem(10) 0;
    
    &__header {
      display: flex;
      align-items: center;
      gap: rem(12);
    }
    
    &__title {
      
    }
    
    &__count {
      color: $color_powderGrey;
      font-size: rem(18);
    }
    
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: rem(12);
    }
  }

  &__projects {
    margin: rem(15) 0;

    &__header {
      display: flex;
      align-items: center;
      gap: rem(12);
    }

    &__count {
      color: $color_powderGrey;
      font-size: rem(18);
    }

    &__filters {
      display: flex;
      gap: rem(16);
      padding-bottom: rem(30);
    }

    &__item {
      margin: rem(15) 0;
    }

  }
}

.bs-theme--dark .bs-colleague {

  &__info__photo {
    background: $color_deepBlack;
  }

  &__actions {
    background: hslAlpha($color_black, .2);
  }

  &__chat__item--active {
    background: $gradient_dark;
  }
}
