.bs-profile-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: rem(60);
  height: rem(60);
  background: $gradient_deep;
  border-radius: 50%;
  box-shadow: 0 0 0 rem(10) $color_orange;
  cursor: pointer;

  svg {
    transition: all .3s ease 0s;
  }

  &:hover svg {
    filter: brightness(1.5);
  }
}
