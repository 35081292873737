.bs-header {
  $header: &;
  position: fixed;
  z-index: 11;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: rem(90);
  transition: all .3s ease 0s;

  @include screenSize(tablet) {
    bottom: auto;
    top: 0;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background: hslAlpha($color_white, 0);
    box-shadow: 0 0 0 0 hslAlpha($color_black, 0);
    opacity: .0;
    transition: all .3s ease 0s;
  }

  &__branding {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(12);
    padding: rem(20);
    height: 100%;
    transition: all .3s ease 0s;

    @include screenSize(desktop) {
      min-width: rem(240);
    }
  }

  &__logo {
    width: rem(52);
    height: rem(52);
    overflow: hidden;
    transition: all .3s ease 0s;

    @include screenSize(desktop) {
      position: absolute;
      left: 0;
      top: 0;
      width: rem(106);
      height: rem(106);
    }
    
    & > * {
      position: relative;
      width: 100%;
      height: 100%;
      transition: all .3s ease 0s;

      @include screenSize(desktop) {
        transform: translate3d(rem(-18), rem(-18), 0);
      }
    }

    svg {
      margin-top: rem(-2);
      width: 50%;
      height: 50%;
      transition: all .3s ease 0s;

      @include screenSize(desktop) {
        margin-top: rem(5);
        margin-left: rem(5);
        width: 44%;
        height: 44%;
      }
    }
  }

  &__sitename {
    display: none;
    width: rem(120);
    transition: all .3s ease 0s;

    @include screenSize(tablet) {
      display: inline-block;
    }

    @include screenSize(desktop) {
      position: absolute;
      left: rem(99);
      top: rem(36);
    }
     
    svg {
      width: 100%;
      fill: hslAlpha($color_black, .7);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(12);
    height: 100%;
    transition: all .3s ease 0s;

    @include screenSize(desktop) {
      width: 100%;
      min-width: rem(320);
      max-width: rem(960);
    }
  }

  &__profile {
    display: none;

    @include screenSize(tablet) {
      display: inline-flex;
    }

    a {
      text-decoration: none;
    }
  }


  &__search {
    display: flex;
    flex: 1;
    gap: rem(12);

    .bs-input {
      width: 100%;

      &.MuiInputBase-root.MuiInputBase-adornedEnd {
        padding-right: 0;
      }
      input {
        padding-right: 0;
        padding-left: rem(10);
        font-size: rem(14);

        @include screenSize(tablet) {
          padding-left: rem(24);
          font-size: rem(20);
        }
      }
    }
  }

  &__options {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(20);
    padding: rem(20);
    height: 100%;
    transition: all .3s ease 0s;

    @include screenSize(desktop) {
      min-width: rem(240);
    }
  }

  &__notifications {

  }

  &__theme {
    display: none;

    @include screenSize(desktop) {
      display: inline-flex;
    }
  }

  &__mobile-menu-toggle {
    position: relative;
    display: inline-flex;
    width: rem(28);
    height: rem(20);
    user-select: none;
    cursor: pointer;
    transition: all 300ms ease-out;

    @include screenSize(desktop) {
      display: none;
    }

    span {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: rem(4);
      background-color: $color_black;
      border-radius: rem(2);
      opacity: 1;
      transform: rotate(0deg);
      transition: all 200ms ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: rem(8);
      }

      &:nth-child(3) {
        top: rem(16);
      }
    }

    &--open {
      z-index: 1001;

      span {

        &:nth-child(1) {
          top: rem(8);
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          left: rem(-28);
          opacity: 0;
        }

        &:nth-child(3) {
          top: rem(8);
          transform: rotate(-135deg)
        }
      }
    }
  }

  &__mobile-menu {
    display: none;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: rem(20);
      margin-bottom: auto;
      width: 100%;
    }

    .bs-profile-tag {
      display: inline-flex;
      width: 100%;
      background: hslAlpha($color_white, 0.5)
    }

    .bs-theme-switch {
      width: 100%;
    }

    &--open {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: rem(20);
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: rem(60);
      background-color: hslAlpha($color_powderGrey, .5);
      backdrop-filter: blur(rem(5));

      @include screenSize(desktop) {
        display: none;
      }

      .bs-menu__item .bs-menu__link {
        span, i {
          color: $color-white;
        }
      }

      .bs-menu__item--active .bs-menu__link {

      }
    }
  }

  &--sticky {
    height: rem(70);

    #{$header}__branding,
    #{$header}__actions,
    #{$header}__options {
      padding-top: rem(10);
      padding-bottom: rem(10);
    }  

    #{$header}__logo > * {
      box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .1);
    }

    #{$header}__sitename {
      top: rem(26);
    }

    #{$header}__bg {
      width: 100%;
      height: 100%;
      background: hslAlpha($color_white, .5);
      box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .1);
      backdrop-filter: blur(rem(5));
      opacity: 1;
    }
  }
}

.bs-header-logo-bg {
  display: none;

  @include screenSize(tablet) {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  &__circle {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    border-radius: 50%;
    box-shadow: inset 0 rem(6) rem(32) 0 hslAlpha($color_black, .015);
    transform: translate3d(-50%, -50%, 0);

    &--small {
      margin: rem(-42) 0 0 rem(39);
      width: rem(420);
      height: rem(420);
      background: hslAlpha($color_black, 0.02);
    }

    &--medium {
      margin: rem(-120) 0 0 rem(120);
      width: rem(960);
      height: rem(960);
      background: hslAlpha($color_black, 0.02);
    }

    &--large {
      margin: rem(-210) 0 0 rem(210);
      width: rem(2100);
      height: rem(2100);
      background: hslAlpha($color_black, 0.01);
    }
  }
}

.bs-theme--dark .bs-header {

  &__sitename {
     
    svg {
      fill: $color_white;
    }
  }

  &__mobile-menu--open {
    background: hslAlpha($color_black, .75);
  }

  &__mobile-menu-toggle span {
    background: $color_white;
  }

  &--sticky {

    .bs-header__bg {
      background: hslAlpha($color_black, .5);
    }
  }
}

.bs-theme--dark .bs-header-logo-bg {

  &__circle {

    &--small {
      background: hslAlpha($color_black, 0.2);
    }

    &--medium {
      background: hslAlpha($color_black, 0.15);
    }

    &--large {
      background: hslAlpha($color_black, 0.1);
    }
  }
}