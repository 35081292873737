.bs-form {
  $form: &;

  &__legend {
    margin: 0 0 rem(24);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__controls {
      align-self: flex-end;
    }
  }

  &__field {
    margin: rem(12) 0;
  }

  &__noty {
    margin: rem(24) 0;
    text-align: right;

    &--error {

    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    gap: rem(12);
    margin: rem(24) 0 0;
  }

  &__custom-input-label {
    padding-left: rem(18);

    &:after {
      content: ':';
    }
  }
}
