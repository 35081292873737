.bs-search-result {
  &__list {
  }

  &__item {
    display: block;
    margin: rem(20) 0;
    text-decoration: none;

    @include screenSize(tablet) {
      margin: rem(40) 0;
    }

    &:hover {
      .bs-search-result__item__title {
        color: $color_darkOrange;
        text-decoration: underline;
      }
    }

    &__title, &__title * {
      font-size: rem(22);
      margin: 0;
      color: $color_gunGrey;

      .search-highlight {
        color: $color_orange;
      }
    }

    &__content {
      color: $color_black;
      margin: rem(8) 0 0 0;

      ul, li, div, p {
        display: inline-block;
        color: $color_black;
      }

      .search-highlight {
        color: $color_orange;
      }
    }
  }
}
