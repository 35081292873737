.bs-link {
  $link: &;
  display: flex;
  align-items: center;
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(20);

  &.MuiLink-root {
    color: $color_powderGrey;
    text-decoration: none;
    cursor: pointer;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem(24);
    height: rem(24);

    &--start {
      margin: 0 rem(12) 0 0;
    }

    &--end {
      margin: 0 0 0 rem(12);
    }
  }
}

.bs-theme--dark .bs-link {

  &.MuiLink-root {
    color: $color_white;
  }
}
