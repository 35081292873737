.bs-page {

  &__header {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    padding: rem(20) 0;
    flex-wrap: wrap;

    @include screenSize(tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__filters,
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(8);

    @include screenSize(tablet) {
      justify-content: initial;
      gap: rem(16);
    }
  }

  &__filters {
    flex-wrap: wrap;
  }

  &__actions {
    flex: 1;
    @include screenSize(tablet) {
      justify-content: flex-end;
    }
  }
}
