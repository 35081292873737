.bs-login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .bs-login-box {
    width: rem(350);
    max-width: 80vw;
    height: rem(300);
    max-height: 40vh;
    padding: rem(30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $color_white;
    border-radius: rem(18);
    box-shadow: $default_card_boxshadow;

    &__logo-text {
      font-size: rem(24);
      color: $color_lightPurple;
      padding: rem(5) 0 rem(30) 0;
    }

    &__error-text {
      color: $color_darkOrange;
    }
  }
}

.bs-theme--dark .bs-login-page .bs-login-box {
  background: $gradient_dark;
  color: $color_white;
}
