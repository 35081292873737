.SnackbarContainer-root {
    
  .SnackbarItem-wrappedRoot {

    .SnackbarContent-root {
      background: $gradient_purple;
      border-radius: rem(25);
      box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .3);
      color: $color_white;

      .MuiSvgIcon-root {
        fill: $color_white;
      }
      
      &.SnackbarItem-variantSuccess {
        background: $gradient_green;
      }
      
      &.SnackbarItem-variantError {
        background: $gradient_red;
      }
    }
  }
}