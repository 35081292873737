.bs-uploader {
  $uploader: &;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: rem(6);
  background: $gradient_purple;
  border-radius: rem(18);

  &__avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(2);
    width: rem(36);
    height: rem(36);
    border-radius: 50%;
    background: $gradient_purple;
  }

  &__image {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    object-fit: cover;

    &--bb {
      margin: rem(-2) 0 0;
      width: rem(20);
      height: rem(20);
      border-radius: 0;
    }
  }

  &__info {
    flex: 1;
    padding: rem(4) rem(16) rem(4) 0;
  }

  &__name,
  &__time {
    line-height: rem(14);
    font-size: rem(12);
    color: $color_white;
  }

  &__name {
    font-weight: 700;
  }

  &--system {
    background: $gradient_orange;

    #{$uploader}__avatar {
      background: $gradient_orange;
    }
  }
}

.bs-theme--dark .bs-uploader {

}
