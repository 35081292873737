html {
  height: 100%;
  font-size: map-get($typo, "size") * 1px;
}

body,
body * {
  box-sizing: border-box;
}

body {
  position: relative;
  height: auto;
  color: $color_deepBlack;

  ::-webkit-scrollbar {
    width: rem(8);
    height: rem(8);
  }
   
  ::-webkit-scrollbar-track {
    background: hslAlpha($color_deepBlack, .1);
    border-radius: rem(4);
  }
   
  ::-webkit-scrollbar-thumb {
    background: hslAlpha($color_lightGrey, .25);
    border-radius: rem(4);
    transition: all .2s ease 0s;

    &:hover {
      background: hslAlpha($color_orange, .9);
    }
  }
   
  ::-webkit-scrollbar-thumb:horizontal {

  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
