.bs-tag {
  display: inline-block;
  padding: rem(10) rem(16);
  background: hslAlpha($color_lightPurple, .5);
  border-radius: rem(18);
  color: $color_white;
  font-size: rem(16);
  font-weight: 700;
  line-height: rem(20);
  cursor: pointer;
  white-space: nowrap;

  &--active {
    background: $gradient_purple;
    color: $color_white;
  }

  &--readonly {
    cursor: default;
  }
}

.bs-theme--dark .bs-tag {
  background: hslAlpha($color_cloudGrey, .12);
  color: $color_white;

  &--active {
    background: $gradient_purple;
    color: $color_white;
  }
}
