.bs-org-chart {
  $org-chart: &;
  transition: all 0.3s ease-in;
  background-color: $color_cloudGreyAlphaLight;
  border-radius: rem(20);

  &--error {
    margin-top: rem(20);
    padding: rem(20);
    text-align: center;
    color: $color_red;

    svg {
      width: 20vw;
      max-width: rem(120);
    }
  }

  &--fullscreen {
    position: fixed;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color_white;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__action-wrapper {
    border-radius: 50%;
    width: rem(22);
    height: rem(22);
    background: $color_orange;
    text-align: center;
    font-weight: 700;
    color: white;

    #{$org-chart}__action {
      &--open {
      }

      &--close {
      }
    }
  }

  &__member {
    position: absolute;
    border-radius: rem(60);
    width: 100%;
    height: rem(60);
    display: flex;
    background: $gradient_light;
    border: rem(2) solid $color_orange;

    &__photo {
      position: absolute;
      border-radius: 100px;
      width: rem(60);
      height: rem(60);
      top: rem(-2);
      left: rem(-2);
      border: rem(2) solid $color_orange;
    }

    &__details-wrapper {
      padding-left: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      padding-right: 30px;
      width: 100%;

      &--team {
        padding-left: 30px;
      }
    }

    &__name {
      font-weight: 700;
      margin: 0;
      font-size: 14px;
      text-align: center;
      line-height: 1rem;
    }

    &__team {
      font-weight: 400;
      margin: 0;
      font-size: 10px;
    }

    &__title {
      font-weight: 300;
      margin: 0;
      font-size: 9px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }
  }
}

.bs-theme--dark .bs-org-chart {
  background: hslAlpha($color_cloudGrey, .12);

  .bs-org-chart {

    &__member {
      background: $gradient_dark;
    }
  }
}