.bs-notifications {
  $notifications: &;

  &__handler {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: rem(36);
    height: rem(36);
    background: $color_white;
    border-radius: rem(18);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
    cursor: pointer;
    transition: all .3s ease 0s;

    svg {
      max-width: rem(20);
      max-height: rem(20);
      fill: $color_powderGrey;
      transition: all .3s ease 0s;
    }

    &:hover {
      box-shadow: 0 rem(2) rem(6) 0 hslAlpha($color_deepBlack, .25);

      svg {
        max-width: rem(20);
        max-height: rem(20);
        fill: $color_orange;
      }
    }
  }

  &__container {
    
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(12);
    padding: rem(15) rem(20);
  }

  &__title {
    color: $color_powderGrey;
    font-size: rem(24);
    font-weight: 700;
  }

  &__loading {
    padding: rem(20);
  }

  &__list {
    padding: 0 rem(20) rem(30);
  }
}

.bs-theme--dark .bs-notifications {

  &__handler {
    background: $gradient_dark;

    svg {
      fill: $color_white;
    }
  }

  &__title {
    color: $color_white;
  }
}
