.bs-input {
  $input: &;

  &.MuiInputBase-root,
  &.MuiAutocomplete-root {
    min-height: rem(52);
    line-height: rem(48);
    border-radius: rem(26);
    border: none;
    box-shadow: inset 0 0 0 rem(1) hslAlpha($color_black, .18);
    transition: all .3s ease 0s;

    .MuiButtonBase-root {
      // min-width: auto;
    }

    .MuiInputBase-input {
      font-family: map-get($typo, "font"), sans-serif;
      font-weight: 700;
    }

    &:hover,
    &.Mui-Focused {
      box-shadow: inset 0 0 0 rem(1) $color_darkPurple;
    }

    &.MuiInputBase-adornedStart,
    &.MuiInputBase-adornedEnd {
      white-space: nowrap;
    }

    &.MuiInputBase-adornedStart {
      padding-left: rem(16);
    }

    &.MuiInputBase-adornedEnd {
      padding-right: rem(16);
    }
  }

  &.MuiAutocomplete-root {
    display: flex;
    line-height: rem(50);

    &::before {
      content: attr(data-label);
      padding: 0 0 0 rem(16);
    }

    .MuiInputBase-root {
      padding: rem(6) rem(9);
    }

    fieldset {
      border: none;
    }
  }

  &--spread.MuiInputBase-root {
    width: 100%;
  }

  &--field.MuiInputBase-root {
    padding: 0 !important;

    .bs-button {
      margin-right: rem(-5);
    }
  }

  &--multiline.MuiInputBase-root {
    align-items: flex-start;
    min-height: rem(156);
  }

  .MuiInputBase-input {
    padding: rem(12) rem(24);
    height: auto;
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 700;
    color: $color_powderGrey;
  }

  &--date-selector {
    padding: rem(12) rem(24);
    height: auto;
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 700;
    color: $color_powderGrey;
    border-radius: rem(26);
    border: none;
    box-shadow: inset 0 0 0 rem(1) hslAlpha($color_black, .18);
  }

  &--small.bs-input {
    &.MuiInputBase-root {
      min-height: rem(18)
    }

    > input.MuiInputBase-input {
      font-size: rem(16);
      padding: rem(6) rem(18);
      font-weight: 400;
    }
  }

  &--has-label {
    &:before {
      font-size: rem(16);
      position: absolute;
      left: rem(16);
      transition: all 0.2s ease-out;
      height: rem(16);
      line-height: rem(16);
      color: rgba(0, 0, 0, 0.6);
    }
  }
  &--has-label.bs-input--label-off {
    &:before {
      font-size: rem(12);
      line-height: rem(12);
      transform: translate(0, rem(-16));
      background: $color_white;
      padding-right: rem(16);
    }
  }
}

.bs-theme--dark .bs-input {

  &.MuiInputBase-root,
  &.MuiAutocomplete-root {
    box-shadow: inset 0 0 0 rem(1) hslAlpha($color_cloudGrey, .5);
    color: $color_white;
  }

  .MuiInputBase-input {
    color: $color_white;
  }

  &--has-label {
    &:before {
      color: $color_white;
    }
  }
  &--has-label.bs-input--label-off {
    &:before {
      background: #35303c;
    }
  }
}

