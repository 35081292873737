.bs-cv-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: rem(10);

  &__input {
    &--full {
      width: 100%;
    }
    &--25 {
      width: calc(25% - 8px);
    }
    &--50 {
      width: calc(50% - 8px);
    }
  }
  &__new-work-description-form {
    display: flex;
    width: 100%;
    gap: rem(10);
    margin-bottom: rem(16);
    padding-left: rem(16);
    position: relative;

    &::before {
      content: '•️';
      position: absolute;
      left: 0;
      top: 0;
      font-size: rem(30);
    }

    &:last-of-type {
      margin-bottom: rem(16);
    }
  }
  &__description-chip {
    &.MuiChip-root{
      margin-top: rem(5);
      margin-bottom: rem(5);
    }
  }

  &__competence {
    display: flex;

    .bs-tech-tag {
      &--included {
      }
      &--not-included {
        opacity: 0.5;
      }

      .bs-tech-tag__action {
        cursor: pointer;
        padding-left: rem(16);
        display: flex;
        align-items: center;

        &:hover {
          svg {
            fill: $color_orange;
          }
        }
      }
      .bs-tech-tag__tech {
        padding-left: rem(8);
      }
    }
  }
}
