.bs-innovation-content {
  &__block {
    padding: rem(24) 0;

    &__title {
      padding: rem(12) 0;
      text-align: center;

      &--orange {
        color: $color_white;
      }
    }

    &__photo {
      img {
        width: 100%;

        @include screenSize(tablet) {
          max-width: rem(860);
        }
      }
    }

    &--orange {
      margin: 0 rem(-40);
      padding: rem(20);
      background-color: $color_orange;
    }

    &__buttons {
      display: flex;
      flex-direction: column;

      @include screenSize(tablet) {
        flex-direction: row;
      }
    }

    &__button {

      &.bs-button.MuiButtonBase-root.bs-button--small {
        margin: rem(6);
      }
      &.bs-button.MuiButtonBase-root.bs-button--secondary {
        color: $color_orange;
      }

      &:hover {
        transform: scale(1.1);
        transition: all .2s ease-in-out 0s;
      }

      &.bs-button.bs-button--secondary-alt {
        color: $color_lighterGrey !important;
      }
      &.bs-button.bs-button--secondary-alt:hover {
        color: $color_lighterGrey !important;;
      }
    }

    &__flow-content {
      display: flex;
      flex-direction: column;
      padding: rem(24) 0;

      @include screenSize(tablet) {
        flex-direction: row;
      }

      &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        
        @include screenSize(tablet) {
          width: 50%;
        }

        svg {
          max-width: rem(220);
          max-height: rem(220);
        }
      }

      &__text {
        width: 100%;

        @include screenSize(tablet) {
          width: 50%;
        }

        &__title {
          color: $color_white;
          font-size: rem(22);
          font-weight: 700;
          text-align: center;
        }

        &__subtitle {
          color: $color_white;
          font-size: rem(16);
          text-align: center;
        }

        &__list {
          padding: rem(24) rem(24) rem(12);

          @include screenSize(tablet) {
            padding-top: rem(24);
          }
        }

        &__list-item {
          padding: rem(12) 0;
          color: $color_white;

          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            width: rem(10);
            height: rem(10);
            margin: rem(5) rem(12) 0 rem(-16);
            background-color: $color_white;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: rem(30) 0;

    @include screenSize(tablet) {
      flex-direction: row;
    }

    &__image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(24);
      width: 100%;
      order: 1;

      @include screenSize(tablet) {
        padding-bottom: 0;
        width: 50%;
        order: inherit
      }

      p {
        color: $color_orange;
        font-size: rem(24);
        font-weight: 700;
        text-align: center;
      }

      &__svg {
        display: flex;
        justify-content: center;
      }

      svg {
        width: rem(200);
        height: rem(200);
      }
    }

    &__text {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      order: 2;

      @include screenSize(tablet) {
        order: inherit;
        width: 40%;
      }

      &__container {
        display: flex;
        flex-direction: column;
      }

      &__items {

      }

      &__item {
        padding: rem(6);

        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          width: rem(10);
          height: rem(10);
          margin: rem(5) rem(12) 0 rem(-16);
          background-color: $color_orange;
          border-radius: 50%;
        }
      }

      &__buttons {
        display: flex;
        justify-content: space-around;
        margin-top: rem(20);

        button:not(:last-child) {
          margin-right: rem(12);
        }

        button {
          a {
            color: $color_white;
          }
        }
      }
    }

    &__form {
      width: 100%;
      height: rem(397);
      margin-left: 0;
      margin-bottom: rem(30);
      padding: 0;
      overflow: hidden;
      box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
      border-radius: rem(8);

      @include screenSize(tablet) {
        width: 60%;
        margin-left: rem(20);
        margin-bottom: 0;
        padding: 0 rem(10);
      }

      iframe {
        width: 100%;
        height: 428px;
        margin-top: -30px;
        background-color: white;
        overflow: hidden;
        border: none;
        border-radius: 8px;
      }

      &__input-block {
        display: flex;
        margin: rem(20) 0;

        .bs-input.MuiInputBase-root {
          width: 100%;
        }

        .bs-input.MuiInputBase-root .MuiInputBase-input, .bs-input.MuiAutocomplete-root .MuiInputBase-input {
          font-size: rem(16);

          &::placeholder {
            font-size: rem(15);
          }
        }
      }

      &__input--50 {
        margin-right: rem(12);
      }

      &__input--100 {
      }

      &__custom-input {
        display: none;
      }

      &__input-label {
        display: flex;
        justify-content: center;
        width: rem(200);
        margin: rem(30) auto;
        padding: rem(6) rem(12);
        border-radius: rem(18);
        border: rem(1) solid $color_cloudGrey;
        cursor: pointer;

        &:hover {
          color: $color_orange;
        }
      }

      &__button {
        display: flex;
        justify-content: center;
        margin-top: rem(24);
      }
    }
  }
}
