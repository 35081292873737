.bs-datetime-tag {
  $datetimetag: &;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: rem(6);
  padding: rem(12);
  min-height: rem(36);
  background: hslAlpha($color_white, .9);
  border-radius: rem(18);
  box-shadow: 0 rem(2) rem(8) 0 hslAlpha($color_black, .1);
  font-size: rem(14);
  color: $color_deepBlack;
}

.bs-theme--dark .bs-uploader {

}
