.bs-cta {
  $cta: &;

  &.MuiButtonBase-root {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    padding: rem(16);
    width: 100%;
    max-width: rem(240);
    border-radius: rem(28);
    line-height: rem(24);
    font-size: rem(20);
    font-weight: 500;
    //background-image: $gradient_fadedPurple;
    //background-size: 100%;
    //-webkit-background-clip: text;
    //-moz-background-clip: text;
    //-webkit-text-fill-color: transparent;
    //-moz-text-fill-color: transparent;
    background: hslAlpha($color_orange, 0.3);

    //&::before {
    //  content: '';
    //  position: absolute;
    //  z-index: -1;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  padding: rem(2);
    //  background: $gradient_fadedPurple;
    //  border-radius: rem(30);
    //  -webkit-mask:
    //     linear-gradient(black 0 0) content-box,
    //     linear-gradient(black 0 0);
    //  -webkit-mask-composite: source-out;
    //  mask-composite: exclude;
    //}

    span {
      color: $color_darkOrange;
    }

    &:hover {
      span {
        color: $color_orange;
      }
    }
  }
}

.bs-theme--dark .bs-cta {
  
  &.MuiButtonBase-root {

  }
}
