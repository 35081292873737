.bs-colleagues {

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(8);
    padding: rem(20) 0;

    @include screenSize(tablet) {
      gap: rem(16);
      justify-content: flex-start;
      align-items: flex-start;
    }

    .bs-button--filter {
      margin-left: auto;
    }

    &__options {
      position: relative;
      margin: 0 rem(-20);
      width: calc(100% + rem(20) + rem(20));
      max-height: 0;
      overflow: hidden;
      transition: all .5s ease 0s;

      &__box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: rem(16);
        padding: rem(20);
        background: hslAlpha($color_black, .03);

        & > .bs-input {
          width: 100%;

          @include screenSize(tablet) {
            width: calc(50% - 16px);
          }
          @include screenSize(highres) {
            width: calc(33% - 16px);
          }
        }
      }

      &--active {
        max-height: rem(300);
      }
    }
  }

  &__profile-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 rem(-10);

    @include screenSize(tablet) {
      padding: rem(10) 0 rem(40);
      margin: 0;
    }
  }

  &__profile-card {
    margin: rem(15) rem(20);

    @include screenSize(tablet) {
      margin: rem(15);
    }
  }
}
