.bs-layout {
  $layout: &;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: $color_lighterGrey;
  overflow-x: hidden;
    
  @include screenSize(tablet) {
    flex-direction: column;
  }

  &__header {
    width: 100%;
    height: 0;
    
    @include screenSize(tablet) {
      height: rem(90);
    }
  }

  &__wrap {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__appbar {
    position: relative;
    display: none;
    justify-content: flex-end;
    
    @include screenSize(desktop) {
      flex: 1;
      display: inline-flex;
      min-width: rem(240);
    }
  }

  &__sidebar {
    position: relative;
    display: none;
    justify-content: flex-start;
    
    @include screenSize(desktop) {
      flex: 1;
      display: inline-flex;
      min-width: rem(240);
    }
  }

  &__content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding: 0 rem(20) rem(40);
    width: 100%;
    background: hslAlpha($color_white, .5);
    box-shadow: 0 rem(2) rem(12) 0 hslAlpha($color_black, .05);
    
    @include screenSize(desktop) {
      min-width: rem(320);
      max-width: rem(960);
      border-radius: rem(20) rem(20) 0 0;
    }

    & > * {
      width: 100%;
    }
  }

  &--app {

  }

  &--auth {

  }
}

.bs-theme--dark .bs-layout {
  background: $color_deepBlack;

  &__appbar {

  }

  &__sidebar {

  }

  &__content {
    background: hslAlpha($color_white, .05);
  }
}
