.bs-cv-form-autocomplete {
  //margin: rem(10);

  &__competence {
    width: rem(250);
  }

  &__language {
    width: rem(250);
  }

  .MuiInputBase-root {
    border-radius: rem(28);
    height: rem(38);
    min-height: rem(38);

    .MuiInputBase-input {
      padding: rem(0) rem(6) !important;
      width: rem(48);
      min-height: rem(18);
    }
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -7px;

    &.Mui-focused,&.MuiFormLabel-filled {
      top: 0;
    }
  }

  &__group-label{
    margin: rem(10);
    background-color: gray;
  }
}

