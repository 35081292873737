.bs-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: rem(900);

  &__prev, &__next {
    display: flex;
    color: $color_orange;
    cursor: pointer;

    span {
      width: rem(20);
      padding-top: rem(3);

      svg {
        width: rem(20);
        color: $color_orange;
      }
    }
  }

  &__next {
    padding-left: rem(20);

    svg {
      margin-left: rem(10);
    }
  }

  &__prev {
    padding-right: rem(20);

    span {
      margin-right: rem(10);
    }
  }
}
