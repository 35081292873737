.bs-point-transaction {
  padding: rem(20);

  &__header__subtitle {
    padding-bottom: rem(20);
  }

  &__action {
    display: flex;
    padding: rem(8) 0;

    &__selector {
      width: rem(80);
      height: rem(50);
      margin-right: rem(16);
      padding: rem(6) rem(16);
      font-size: rem(20);
      background-color: $color_white;
      border-color: $color_cloudGrey;
      border-radius: rem(26);

      &:focus-visible {
        border: rem(1) solid $color_purple;
      }
    }

    &__input {
      margin-left: rem(16);

      &.bs-input {
        width: rem(80);
      }

      .MuiInputBase-input {
        padding: 0 rem(6);
        text-align: center;
      }
    }
  }

  &__message-input {
    width: 100%;
    margin: rem(20) 0;
  }
}
