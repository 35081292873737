.bs-cv-form-datepicker {
  &__education {
    .MuiInputBase-root {
      border-radius: rem(28);
      min-height: rem(18);

      .MuiInputBase-input {
        padding: rem(6) rem(12);
        min-height: rem(18);
        width: rem(100);
      }
    }
  }

  .MuiInputBase-root {
    border-radius: rem(28);
    min-height: rem(18);

    .MuiInputBase-input {
      padding: rem(6) rem(12);
      width: 100%;
      min-height: rem(18);
    }
  }

  &--with-month {
    .MuiInputBase-root {
      .MuiInputBase-input {
        width: 100%;
      }
    }
  }

  &--small {
    label.MuiFormLabel-root {
      top: rem(-10);

      &.MuiFormLabel-filled,
      &.Mui-focused {
        top: rem(0);
      }
    }

    .MuiInputBase-root {
      .MuiInputBase-input {
        font-size: rem(16);
        padding: rem(6) rem(18);
        font-weight: 400;
      }
    }
  }
}
