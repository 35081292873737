.bs-welcome-tutorial {
  position: relative;
  padding: rem(30);

  @include screenSize(tablet) {
    height: rem(690);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: rem(24);

    &--center {
      justify-content: center;
    }
  }

  &__title {
    font-size: rem(26);
    font-weight: 600;
    color: $color_orange;
    text-align: center;
  }

  &__text {
    line-height: rem(22);
    text-align: justify;

    a {
      color: $color_orange;
    }
  }

  &__list-item {
    padding: rem(8) 0;
  }

  &__photo {
    display: flex;
    margin: auto;
    max-height: rem(250);

    &--with-border {
      border: rem(1) solid $color_orange;
      border-radius: rem(18);
      box-shadow: $default_card_boxshadow;
    }
  }

  &__navigation {
    position: absolute;
    bottom: rem(30);
    right: 0;
    left: 0;
  }

  &__dots {
    display: flex;
    justify-content: center;
  }

  &__dot {
    width: rem(8);
    height: rem(8);
    margin: rem(4);
    border-radius: 50%;
    border: rem(1) solid $color_orange;

    &--active {
      background-color: $color_orange;
    }
  }

  &__start-button {

    &.bs-button {
      margin-left: rem(24);
    }
  }
}
