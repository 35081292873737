.bs-no-result {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(30);
  width: 100%;
  min-height: rem(90);
  border-radius: rem(18);
  box-shadow: inset 0 0 0 rem(2) hslAlpha($color_cloudGrey, .25);

  &__label {
    font-size: rem(20);
    color: $color_powderGrey;
  }

  &--spacing {
    margin: rem(20) 0;
  }
}

.bs-theme--dark .bs-no-result {
  box-shadow: inset 0 0 0 rem(2) hslAlpha($color_deepBlack, .75);

  &__label {
    color: $color_white;
  }
}
