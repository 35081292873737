.bs-cv-form-number {
    .MuiInputBase-root {
      border-radius: rem(28);
      min-height: rem(18);

      .MuiInputBase-input {
        padding: rem(6) rem(12);
        width: rem(48);
        min-height: rem(18);
      }
    }
  }
