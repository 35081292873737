.bs-button {
  $button: &;
  transition: all .3s ease 0s;

  &.Mui-disabled {
    opacity: 0.5;
  }
  &.MuiButtonBase-root {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: rem(5);
    padding: rem(12) rem(24);
    min-width: rem(52);
    min-height: rem(52);
    background: $gradient_orange;
    border: none;
    border-radius: rem(26);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
    line-height: rem(26);
    font-size: rem(20);
    font-weight: 500;
    color: $color_white;
    cursor: pointer;

    .text {
      line-height: rem(26);
      font-size: rem(20);
      font-weight: 500;
    }

    &#{$button}--secondary {
      background: $color_white;
      color: $color_powderGrey;

      &:hover {
        color: $color_orange;
      }
    }

    &#{$button}--secondary-alt {
      background: hslAlpha($color_white, .2);

      color: $color_darkOrange;

      &:hover {
        color: $color_orange;
      }
    }

    &#{$button}--small {
      padding: rem(8) rem(16);
      min-width: rem(36);
      min-height: rem(36);
      border-radius: rem(20);
      line-height: rem(20);
      font-size: rem(18);

      &:disabled {
        opacity: 0.6;
      }
    }

    &#{$button}--large {
      padding: rem(16) rem(32);
      min-width: rem(64);
      min-height: rem(64);
      border-radius: rem(32);
      line-height: rem(30);
      font-size: rem(24);
    }

    &#{$button}--icon {
      padding: 0;
      border-radius: 50%;
    }

    &#{$button}--one-liner {
      white-space: nowrap;
    }

    &#{$button}--spread {
      width: 100%;
    }

    &.bs-helper-mobile-icon {

      @include screenSize(onMobile) {
        padding: 0;
        border-radius: 50%;
      }
    }
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem(24);
    height: rem(24);

    &--start {
      margin: 0 rem(12) 0 0;
    }

    &--end {
      margin: 0 0 0 rem(12);
    }
  }

  &--icon i {
    margin: 0;
  }

  &:hover {
    filter: brightness(1.3);
  }

  &__group {
    border-radius: rem(26);
    box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);

    > .bs-button {
      border-radius: 0;
      box-shadow: none;
      border-left: solid rem(1) hslAlpha($color_cloudGrey, .5);

      &:first-of-type {
        border-radius: rem(26) 0 0 rem(26);
      }
      &:last-of-type {
        border-radius: 0 rem(26) rem(26) 0;
      }

      svg {
        width: rem(14);

        @include screenSize(tablet) {
          width: rem(24);
        }
      }
    }

    &:hover {
      .bs-button {
        filter: none;
      }
    }
  }
}

.bs-theme--dark .bs-button {

  &.MuiButtonBase-root {

    &.bs-button--secondary {
      background: $gradient_dark;
      color: $color_orange;
    }

    &.bs-button--secondary-alt {
      background: hslAlpha($color_gunGrey, .2);
      color: hslAlpha($color_orange, .9);
    }
  }
}
