@use "sass:math";

.bs-admin {
  $admin: &;
  padding: rem(20) 0;
  width: 100%;

  @include screenSize(tablet) {
    padding: rem(30) rem(10);
  }

  &__section {
    background-color: $color_white;
    border-radius: rem(18);
    box-shadow: $default_card_boxshadow;
    padding: rem(18);
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);
    margin-bottom: rem(15);

    h3 {
      margin: 0;
      width: 100%;
    }

    h5 {
      margin: rem(12) 0 rem(6);
      width: 100%;
    }
  }

  &__sub-section {
    margin: rem(18);
    width: 100%;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);
    margin: rem(6) 0;
  }

  &__title {

  }

  &__grid {

    &__row {
      display: flex;
      justify-content: space-around;

      &__medal, &__owner {
        padding: rem(12);
        width: 50%;
        border: rem(1) solid $color_black;
      }
    }
  }

  &__editor {

  }

  &__content {

    .box {
      margin: rem(20) 0;

      &:first-of-type {
        margin: rem(-20) 0 0;

        .columns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: rem(12);
        }
      }
    }

    a.button,
    .MuiButton-root {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: rem(8) rem(16);
      min-width: rem(36);
      min-height: rem(36);
      background: $color_white;
      border: none;
      border-radius: rem(20);
      box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_deepBlack, .12);
      line-height: rem(20);
      font-size: rem(18);
      font-weight: 500;
      color: $color_powderGrey;
      text-decoration: none;
      text-transform: none;
      cursor: pointer;

      &:hover {
        background: $color_white;
        filter: brightness(1.5);
      }
    }

    a.button {
      background: $color_orange;
      color: $color_white;

      &:hover {
        background: $color_orange;
      }
    }

    .input {
      padding: 0 rem(20);
      width: 100%;
      min-height: rem(52);
      line-height: rem(48);
      border-radius: rem(26);
      border: none;
      outline: none;
      box-shadow: inset 0 0 0 rem(1) $color_cloudGrey;
      transition: all .3s ease 0s;

      &:hover {
        box-shadow: inset 0 0 0 rem(1) $color_darkPurple;
      }
    }

    table {
      margin: rem(20) 0;
      width: 100%;

      thead th {
        text-align: left;
      }
    }

    .is-flex {
      display: flex;
      width: 100%;

      &.is-justify-content-space-between {
        justify-content: space-between;
      }

      &.is-justify-content-flex-end {
        justify-content: flex-end;
      }
    }

    .paginator,
    .has-text-grey-dark {
      opacity: .5;

      .pager {
        padding-right: rem(8);
        cursor: pointer;

        &:hover {
          color: $color_orange;
        }
        &--active {
          color: $color_darkOrange;
        }
      }
    }

    .MuiGrid-container {
      position: relative;
      gap: rem(20);

      .MuiGrid-grid-xs-6 {
        max-width: calc(50% - rem(math.div(20, 2)));
      }

      .MuiGrid-grid-xs-12 {
        max-width: calc(50% - rem(math.div(20, 2)));

        @include screenSize('tablet') {
          width: 100%;
          max-width: calc(100% - rem(math.div(20, 2)));
        }
      }
    }

    .FormFieldGrid-grid {
      flex: none;
      width: 100%;
      max-width: 100%;
    }

    .MuiFormControl-root {
      margin: 0;
    }

    .MuiOutlinedInput-root {
      border-radius: rem(12);
    }
  }

  .image-selector {
    &__image-browser {
      width: 100%;

      &__img-wrapper {
        display: flex;
        justify-content: center;
      }
      img {
        max-height: rem(350);
      }
    }
    &__file-selector {
      display: none;
    }

    &__image-empty {
      display: none;
    }
  }

  &__cv-list {
    table {
      width: 100%;
    }
  }
}

.bs-theme--dark .bs-admin {

  &__section {
    background: $gradient_dark;
    color: $color_white;
  }

  &__content {

    .input {
      background: $color_deepBlack;
      color: $color_white;
    }

    .MuiButton-root {
      background: $gradient_dark;
      color: $color_white;
    }
  }
}
