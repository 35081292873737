.bs-back-to-top {
  position: fixed;
  bottom: rem(50);
  right: rem(50);
  z-index: 1;
  transition: all .3s ease 0s;

  @include screenSize(onMobile) {
    right: rem(30);
    bottom: rem(80);
  }

  &--hidden {
    opacity: 0;
  }

  &--show {
    opacity: 1;
  }
}
