.bs-bd-decoration {
  position: fixed;

  &__confetti {
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
  }

  &__balloons {
    right: 0;
    bottom: 0;
    width: 350%;

    @include screenSize(tablet) {
      width: 300%;
    }

    @include screenSize(desktop) {
      width: 100%;
    }
  }

  &__label {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include screenSize(onMobile) {
      transform: translate(-50%, -50%) scale(2);
    }
  }

  &__garland {
    position: fixed;

    @include screenSize(tablet) {
      width: 50vw;
    }

    @include screenSize(desktop) {
      width: 30vw;
    }

    &--0 {
      top: rem(-20);
      left: rem(-30);
      transform: rotate(-25deg);
    }

    &--1 {
      top: rem(-35);
      left: 20%;
      transform: rotate(4deg);
    }

    &--2 {
      top: rem(-35);
      left: 50%;
      transform: rotate(4deg);
    }

    &--3 {
      top: 0;
      right: rem(-10);
      transform: rotate(30deg);
    }
  }
}
