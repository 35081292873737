.bs-post-article {
  position: relative;
  margin: rem(24) 0;
  padding: rem(24);
  min-height: rem(240);
  background: $color_white;
  border-radius: rem(18);
  box-shadow: 0 rem(4) rem(12) 0 hslAlpha($color_black, .12);
  z-index: 14;

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hslAlpha($color_steelGrey, 0.4);
    z-index: 13;
  }
}

.bs-theme--dark .bs-post-article {
  background: hslAlpha($color_cloudGrey, .12);
}